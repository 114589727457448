<template>
    <div class="project-info-container">
        <div class="title-container">
            <span class="cancel" @click="cancel">取消</span>
            <span>编辑作品信息</span>
            <span class="confirm" @click="confirm">确定</span>
        </div>
        <div class="project-input-main-container">
            <div class="project-input-left-container">
                <div class="project-inof-name-label"><span class="input-required">作品标题</span> </div>
                <div class="project-inof-name-input">
                    <pano-input placeholder="请输入作品标题" v-model="myName"></pano-input>
                </div>
            </div>
            <div class="project-input-right-container">
                <img class="project-input-right-img" :src="myCover" alt="">
            </div>
        </div>
        <div class="project-info-desc-input-container">
            <div class="project-info-desc-label">作品简介</div>
            <div class="project-info-desc-input">
                <textarea class="project-info-desc-input-area" v-model="myDescription" placeholder="填写作品简介，最多150个字"></textarea>
            </div>
        </div>
    </div>
</template>
<script>
import { saveProject } from "api/pano/pano";
import { mapActions, mapGetters, mapState } from "vuex";
export default {
    computed: {
        ...mapState("webappEditModule", ["cover", "name", "description"]),
        ...mapGetters("webappEditModule", ["myProject"]),
    },
    mounted() {
        this.myCover = this.cover;
        this.myName = this.name;
        this.myDescription = this.description;
    },
    watch: {
        myCover() {
            this.onProjectCoverModify(this.myCover);
        },
        myName() {
            this.onProjectNameModify(this.myName);
        },
        myDescription() {
            this.onProjectDescModify(this.myDescription);
        }
    },
    data() {
        return {
            myCover: null,
            myName: null,
            myDescription: null,
        };
    },
    methods: {
        ...mapActions("webappEditModule", ["onSlideClose","onProjectNameModify","onProjectCoverModify","onProjectDescModify"]),
        cancel() {
            this.onSlideClose();
        },
        async confirm() {
            console.log(this.name);
            console.log(this.description);
            console.log(this.myProject);
            await saveProject(this.myProject);
            this.$alert({content:'发布成功'},()=>{
                window.location=window.origin
            })
        },
    },
};
</script>
<style lang="scss" scoped>
.project-info-container {
    height: 100vh;
    background: $pano-framework-tool-item-background;
    .title-container {
        background: $pano-framework-background;
        display: flex;
        justify-content: space-between;
        align-items: center;
        height: 60px;
        .confirm {
            color: $pano-theme;
        }
        .cancel,
        .confirm {
            padding: 0 20px;
        }
    }
    .project-input-main-container {
        padding: 20px;
        display: flex;
        border-bottom: 0.5px solid rgba(#7b7a7a, 0.6);
        .project-input-left-container {
            flex: 1;
            height: 120px;
            display: flex;
            flex-direction: column;
            .project-inof-name-label {
                margin-bottom: 20px;
            }
            .project-inof-name-input {
                flex: 1;
                padding-right: 20px;
                ::v-deep .pano-input {
                    background: $pano-framework-tool-item-background;
                    color: $pano-font-color;
                    padding-left: 0;
                    border-top: none;
                    border-left: none;
                    border-right: none;
                    border-radius: 0;
                }
                ::v-deep .pano-input:hover {
                    border-color: $pano-theme;
                }
            }
        }
        .project-input-right-container {
            height: 120px;
            width: 120px;
            border-radius: 6px;
            overflow: hidden;
            .project-input-right-img {
                height: 120px;
                width: 120px;
            }
        }
    }
    .project-info-desc-input-container {
        padding: 20px;
        .project-info-desc-label {
            margin-bottom: 20px;
        }
        .project-info-desc-input {
            .project-info-desc-input-area {
                background: $pano-framework-tool-item-background;
                height: 120px;
                width: 100%;
                color: #ffffff;
                border: 1px solid transparent;
            }
            .project-info-desc-input-area:hover {
                border: 1px solid $pano-theme;
            }
        }
    }
}
</style>