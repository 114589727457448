export default {
    xml:null,
    krpano: null,
    id:null,
    name:'',
    description:null,
    cover:null,
    scenes: [],
    currentSceneId: '',
}
