import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import Alert from 'components/alert'
import Notify from 'components/notify'
import Modal from 'components/modal'
import Popup from 'components/popup'
import Button from 'components/pano-button'
import Input from 'components/pano-input'
import CircleClose from 'components/pano-circle-close'
import ModalTitle from 'components/pano-modal-title'
import Switch from 'components/pano-switch'
import Select from 'components/pano-select'
import SelectItem from 'components/pano-select/components/pano-select-item'
import Vant from 'vant';
import 'vant/lib/index.css';

Vue.use(Vant);
Vue.config.productionTip = false
Vue.prototype.$url = (url) => {
  return location.origin + location.pathname + '?t=' + new Date().getTime() + '#/' + url
}
Vue.use(Alert).use(Notify).use(Modal).use(Popup).use(Button).use(Input).use(CircleClose).use(ModalTitle)
.use(Switch).use(Select).use(SelectItem)
var app = new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')

window.APP = app