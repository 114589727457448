<template >
  <div class="pano-button-container ellipsis" 
  :class="{
    'pano-button-small':size=='small',
  'pano-button-medium':size=='medium',
  'pano-button-large':size=='large',
  'pano-button-default':type=='default',
  'pano-button-del':type=='del'
  }"
  @click="click()"
  >
    {{ name }}
  </div>
</template>
<script>
export default {
  name: "PanoButton",
  props: {
    size:{
      type:String,
      default:'small'
    },
    type:{
      type:String,
      default:'default'
    },
    name: {
      type: String,
      default: "按钮",
    },
  },
  methods:{
    click(){
      this.$emit('click');
    }
  }
};
</script>
<style lang="scss">
.pano-button-container {
  transition: all 0.3s ease-in-out;
  font-size: 14px;
  color: #ffffff;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 10px;
  text-align: center;
}
.pano-button-small{
  font-size: $font-size-small;
  line-height: 30px;
}
.pano-button-medium{
  font-size: $font-size-medium;
  line-height: 36px;
}
.pano-button-large{
  font-size: $font-size-large;
  line-height: 42px;
}
.pano-button-default{
  background: $color-theme;
}
.pano-button-default:hover{
  cursor: pointer;
  background: $color-theme-deep;
}
.pano-button-del{
  background: $color-danger;
}
.pano-button-del:hover{
  cursor: pointer;
  background: $color-danger-deep;
}
</style>