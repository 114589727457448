<template>
    <div class="pano-modal-title-container">
        <div>{{name}}</div>
    </div>
</template>
<script>
export default {
    props:{
        name:{
            type:String,
            default:'modal title'
        }
    }
}
</script>
<style lang="scss">
    .pano-modal-title-container{
        line-height: 50px;
        padding: 0 20px;
        border-bottom: 1px solid #eeeeee;
        color: $color-text-deep;
        font-size: $font-size-medium-x;
    }
</style>