
export default class Collect {
    static remove (arr,predicate) {
        
        for(var i=0;i<arr.length;i++){
        if(predicate(arr[i])){
            arr.splice(i,1);//删除下标为i 的元素，i开始只删除一个就它自己了
            i--;//删除一个元素后数组长度减一了     
        }
    }
    }
  }
  