const initKrpano = (kp) => {
    kp.set("BDDomain", "https://res.jzyglxt.com");
}
const hiddenTool = (kp) => {
    // kp.set('layer[skin_control_bar].visible', false);
    // kp.set('layer[skin_splitter_bottom].visible', false);
    // kp.set('layer[skin_scroll_window].visible', false);
}
const loadScene = (kp, sceneId) => {
    kp.call('loadscene(' + sceneId + ', null, MERGE,BLEND(1.0, easeInCubic));');
}
const loadStar = (kp) => {
    // kp.call('skin_view_littleplanet();');
    setTimeout(() => {
        kp.call('skin_view_littleplanet();');
        // setTimeout(() => {
        //     kp.call('skin_view_normal();');
        // }, 3000);
    }, 1500);
}
const startView = (kp) => {
    kp.call('skin_view_normal();');
}

const loadSceneWithParams = (kp, sceneId, params) => {
    kp.call('loadscene(' + sceneId + ', ' + params + ', MERGE,BLEND(1.0, easeInCubic));');
}
const addHotspot = (kp, hotspot) => {

    let hs = Object.assign({}, hotspot)
    const { icon, id, size } = hs
    let panoScreen = document.querySelector("#webapp-pano");
    kp.set("curscreen_x", panoScreen.clientWidth / 2);
    kp.set("curscreen_y", panoScreen.clientHeight / 2);
    kp.call("screentosphere(curscreen_x, curscreen_y, curscreen_ath, curscreen_atv);");
    let ath = kp.get("curscreen_ath");
    let atv = kp.get("curscreen_atv");
    hs.ath = ath;
    hs.atv = atv;
    hs.orderBy = 0;
    kp.call(`addSceneHotspot('${icon}','${id}','${ath}','${atv}','${size}')`);
    return hs;
}
const loadHotspot = (kp, hotspot) => {
    const { icon, id, size, ath, atv } = hotspot
    kp.call(`addSceneHotspot('${icon}','${id}','${ath}','${atv}','${size}')`);
    return hotspot;
}
const loadHotspotWithThumb = (kp, hotspot) => {
    const { iconThumb, id, size, ath, atv } = hotspot
    kp.call(`addSceneHotspot('${iconThumb}','${id}','${ath}','${atv}','${size}')`);
    return hotspot;
}
const loadViewHotspot = (kp, hotspot) => {
    const { icon, id, size, ath, atv, toSceneId } = hotspot
    // let p=`view.vlookat=${toSceneAtvLookAt}&view.hlookat=${toSceneAthLookAt}`
    // console.log(p)
    kp.call(`addViewSceneHotspot('${icon}','${id}','${ath}','${atv}','${size}','${toSceneId}')`);
    return hotspot;
}
const modifyHostpotIconThumb = (kp, hotspot) => {
    const { iconThumb, id } = hotspot
    kp.call(`modifySceneHotspotIcon('${id}','${iconThumb}')`)
}
const modifyHostpotIcon = (kp, hotspot) => {
    const { icon, id } = hotspot
    kp.call(`modifySceneHotspotIcon('${id}','${icon}')`)
}
const modifySceneHotspotName = (kp, hotspot) => {
    const { name, id, fontSize } = hotspot
    kp.call(`modifySceneHotspotName(${id},${name},${fontSize})`)
}
const getSceneScreenCenter = (kp, docId) => {
    let panoScreen = document.querySelector(docId);
    kp.set("curscreen_x", panoScreen.clientWidth / 2);
    kp.set("curscreen_y", panoScreen.clientHeight / 2);
    kp.call("screentosphere(curscreen_x, curscreen_y, curscreen_ath, curscreen_atv);");
    let ath = kp.get("curscreen_ath");
    let atv = kp.get("curscreen_atv");
    return {
        ath, atv
    }
}
const lookToHotspot = (kp, hotspotId) => {
    let curFov = kp.get('view.fov')
    kp.call(`looktohotspot(${hotspotId},${curFov})`);
}
const removeHotspot = (kp, hotspotId) => {
    kp.call(`removehotspot(${hotspotId});`);
}
export {
    hiddenTool,
    loadScene,
    addHotspot,
    modifyHostpotIcon,
    modifySceneHotspotName,
    getSceneScreenCenter,
    loadSceneWithParams,
    lookToHotspot,
    removeHotspot,
    loadHotspot,
    initKrpano,
    loadViewHotspot,
    modifyHostpotIconThumb,
    loadHotspotWithThumb,
    loadStar,
    startView
}