import Vue from 'vue'
import Vuex from 'vuex'
import editModule from '../views/edit/store'
import publishModule from '../views/my/publish/store'
import webappEditModule from '../views/webapp/edit/store'
import webappPanoModule from '../views/webapp/pano/store'
import webappPanoViewModule from '../views/webapp/panoView/store'

Vue.use(Vuex)

export default new Vuex.Store({
  modules: {
    editModule,
    publishModule,
    webappEditModule,
    webappPanoModule,
    webappPanoViewModule
  }
})
