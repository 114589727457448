import { post } from 'common/js/http'

export function myPanoProject (projectId) {
  return post('/api/app/pano/myPanoProject', {id:projectId})
}
export function saveProject (data) {
  return post('/api/app/pano/saveProject', data)
}
export function myProjectPage (data) {
  return post('/api/app/pano/myProjectPage', data)
}

export function materialPage (data) {
  return post('/api/app/pano/materialPage', data)
}
export function uploadFile (data) {
  return post('/api/app/pano/materialPage', data)
}
export function addMaterial (data) {
  return post('/api/app/pano/addMaterial', data)
}
export function createProject (data) {
  return post('/api/app/pano/createProject', data)
}
export function transfer (data) {
  return post('/api/common/record/transfer', data)
}