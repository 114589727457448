export default{
  sysIcons:[
    {id:'1',iconUrl:'static/def-material/hot-spots/spot_1.png'},
    {id:'2',iconUrl:'static/def-material/hot-spots/spot_2.png'},
    {id:'3',iconUrl:'static/def-material/hot-spots/spot_3.png'},
    {id:'4',iconUrl:'static/def-material/hot-spots/spot_4.png'},
    {id:'5',iconUrl:'static/def-material/hot-spots/spot_5.png'},
    {id:'6',iconUrl:'static/def-material/hot-spots/spot_6.png'},
    {id:'7',iconUrl:'static/def-material/hot-spots/spot_7.png'},
    {id:'8',iconUrl:'static/def-material/hot-spots/spot_8.png'},
    {id:'9',iconUrl:'static/def-material/hot-spots/spot_9.png'},
    {id:'10',iconUrl:'static/def-material/hot-spots/spot_10.png'},
    {id:'11',iconUrl:'static/def-material/hot-spots/spot_11.png'},
    {id:'12',iconUrl:'static/def-material/hot-spots/spot_12.png'},
    {id:'13',iconUrl:'static/def-material/hot-spots/spot_13.png'},
    {id:'14',iconUrl:'static/def-material/hot-spots/spot_14.png'},
    {id:'15',iconUrl:'static/def-material/hot-spots/spot_15.png'},
    {id:'16',iconUrl:'static/def-material/hot-spots/spot_16.png'},
    {id:'17',iconUrl:'static/def-material/hot-spots/spot_17.png'},
    {id:'18',iconUrl:'static/def-material/hot-spots/spot_18.png'}
  ]
}