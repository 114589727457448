<template>
    <div class="hotspot-prop-container">
        <PropScroll>
            <div class="hotspot-content-container">
                <div class="hotspot-prop-select">
                    <pano-button class="hotspot-pano-button" name="添加热点" @click="addHotSpots()"></pano-button>
                    <i class="iconfont">&#xe6d8;</i>
                </div>
                <div class="hotspot-items-container">
                    <div class="hotspot-item-title">
                        当前场景热点({{currentSceneHotspots.length}})
                    </div>
                    <div class="hotspot-item-content-container">
                        <div class="hotspot-item-content"  @click="updateHotspot(item.hsId)" :class="{'hotspot-item-content-active':currentHotspotId==item.hsId}" v-for="item in currentSceneHotspots" :key="item.hsId">
                            <div class="hotspot-item-content-icon">
                                <img :src="item.iconUrl" alt="">
                            </div> 
                            <div class="hotspot-item-content-title ellipsis">
                              <span :title="item.title">{{item.title}}</span>
                            </div>
                            <div class="hotspot-item-content-end">全景切换</div>
                        </div>
                    </div>
                </div>
            </div>
        </PropScroll>
    </div>
</template>
<script>
import PropScroll from "../prop-scroll";
import HotspotEditView from "./components/hotspot-edit-view";
import { mapState, mapActions, mapGetters } from "vuex";
export default {
    components: {
        PropScroll,
    },
    computed: {
        ...mapGetters("editModule", ["currentSceneHotspots", "currentHotspot"]),
        ...mapState("editModule", ["slide"]),
        currentHotspotId() {
          console.log(this.currentHotspot)
            if (this.currentHotspot) {
                return this.currentHotspot.hsId;
            }
            return null;
        },
    },
    methods: {
        ...mapActions("editModule", ["openSlide"]),
        addHotSpots() {
            this.openSlide({ type: HotspotEditView, reload: false,params:{hsId : null} });
        },
        updateHotspot(hsId){
            this.openSlide({ type: HotspotEditView, reload: false,params:{hsId : hsId} });
        }
    }
};
</script>
<style lang="scss" scoped>
.hotspot-prop-container {
    display: flex;
    flex-direction: column;
    height: 100%;
    .hotspot-content-container {
        flex: 1;
        .hotspot-prop-select {
            padding: 20px;
            border-bottom: 1px solid #222222;
            display: flex;
            align-items: center;
            .hotspot-pano-button {
                flex: 1;
            }
            .iconfont {
                margin: 0 10px 0 30px;
            }
        }
        .hotspot-items-container {
            border-top: 1px solid #4b4b4b;
            .hotspot-item-title {
                padding: 20px;
                color: #ffffff;
            }
            .hotspot-item-content-container {
                .hotspot-item-content {
                    background: #444444;
                    margin-bottom: 10px;
                    color: #ffffff;
                    padding: 10px 10px;
                    display: flex;
                    line-height: 30px;
                    .hotspot-item-content-icon {
                        width: 30px;
                        height: 30px;
                        margin-right: 10px;
                        img {
                            height: 100%;
                        }
                    }
                    .hotspot-item-content-title{
                      flex: 1;
                      width: 1px;
                    }
                    .hotspot-item-content-end{
                      margin-left: 10px;
                      color: #999898;
                    }
                }
                .hotspot-item-content:hover{
                  cursor: pointer;
                  background: #999898;
                }
            }
        }
    }
}
</style>