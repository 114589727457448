<template>
  <div class="view-prop-container">
    <prop-scroll>
      <div class="view-slider-container">
        <div class="view-prop-title">
          <fov-set></fov-set>
        </div>
        <div class="view-prop-title">
          <horizontal-vertical-fov-set
            title="垂直视角限制"
            :min="-90"
            :max="90"
            :minValue="-85"
            :maxValue="65"
          ></horizontal-vertical-fov-set>
        </div>
        <div class="view-prop-title">
          <horizontal-vertical-fov-set
            title="水平视角限制"
            :min="-180"
            :max="180"
            :minValue="-95"
            :maxValue="65"
          ></horizontal-vertical-fov-set>
        </div>
        <div class="view-prop-item-container">
          <div class="view-prop-item-left">自动巡游时，保持初始视角</div>
          <div class="view-prop-item-right">ck</div>
        </div>
        <div class="view-prop-item-container">
          <div class="view-prop-item-left">应用到</div>
          <div class="view-prop-item-right">ck</div>
        </div>
      </div>
    </prop-scroll>
    <reset-complete></reset-complete>
  </div>
</template>
<script>
import PropScroll from "../prop-scroll";
import FovSet from "./components/fov-set";
import HorizontalVerticalFovSet from "./components/horizontal-vertical-fov-set";
import ResetComplete from "./components/reset-complete";
export default {
  methods: {
    valChange() {},
  },
  components: {
    PropScroll,
    FovSet,
    HorizontalVerticalFovSet,
    ResetComplete,
  },
};
</script>
<style lang="scss">
.view-prop-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  .view-slider-container {
    padding: 10px 0 0 0;
    flex: 1;
    .view-prop-title {
      padding: 30px 10px;
      border-top: 1px solid #4b4b4b;
      border-bottom: 1px solid #222222;
    }
    .view-prop-title:first-child {
      padding: 20px 10px 30px 10px !important;
      border-top: none;
    }
    .view-prop-item-container {
      display: flex;
      justify-content: space-between;
      padding: 30px 10px;
      border-top: 1px solid #4b4b4b;
      border-bottom: 1px solid #222222;
      color: #ffffff;
    }
    .view-prop-item-container:last-child {
      border-bottom: none;
    }
  }
  .view-reset-container {
    border-top: 1px solid #4b4b4b;
    border-bottom: 1px solid #222222;
    display: flex;
    align-items: center;
    justify-content: center;
    max-height: 200px;
  }
}
</style>