<template>
  <div class="hotspot-edit-scroll-container">
    <slot></slot>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
.hotspot-edit-scroll-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  border-top: 1px solid #4b4b4b;
  border-bottom: 1px solid #222222;
}
.hotspot-edit-scroll-container::-webkit-scrollbar {
  display: none;
}
</style>