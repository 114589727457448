
export default class Util {
  static isMobile (mobile) {
    return /^1\d{10}$/.test(mobile)
  }
  static isEmpty (obj) {
    if (typeof obj === 'undefined' || obj == null || obj === '' || (obj + '').trim().length === 0 || obj === 'null') {
      return true
    } else {
      return false
    }
  }
  static timestampToTime (timestamp, state) {
    const date = new Date(timestamp)
    const Y = date.getFullYear() + '-'
    const M = (date.getMonth() + 1 + '').padStart(2, '0') + '-'
    const D = (date.getDate() + '').padStart(2, '0') + ' '
    const h = (date.getHours() + '').padStart(2, '0') + ':'
    const m = (date.getMinutes + '').padStart(2, '0') + ':'
    const s = (date.getSeconds() + '').padStart(2, '0')
    return state ? Y + M + D + h + m + s : Y + M + D
  }
  static dataURItoBlob (dataURI) {
    const byteString = atob(dataURI.split(',')[1])
    const mimeString = dataURI.split(',')[0].split(':')[1].split(';')[0]
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    return new Blob([ab], { type: mimeString })
  }
  static  base64ToBlob(base64) {
    const byteString = atob(base64.split(',')[1])
    const mimeString = base64.split(',')[0].split(':')[1].split(';')[0]
    const ab = new ArrayBuffer(byteString.length)
    const ia = new Uint8Array(ab)
    for (let i = 0; i < byteString.length; i++) {
      ia[i] = byteString.charCodeAt(i)
    }
    return new Blob([ab], {
      type: mimeString
    })
  }
  static toDecimal2 (x) {
    let f = parseFloat(x)
    if (isNaN(f)) {
      return false
    }
    f = Math.round(x * 100) / 100
    let s = f.toString()
    let rs = s.indexOf('.')
    if (rs < 0) {
      rs = s.length
      s += '.'
    }
    while (s.length <= rs + 2) {
      s += '0'
    }
    return parseFloat(s)
  }
  static toDecimal1 (x) {
    let f = parseFloat(x)
    if (isNaN(f)) {
      return false
    }
    f = Math.round(x * 10) / 10
    let s = f.toString()
    let rs = s.indexOf('.')
    if (rs < 0) {
      rs = s.length
      s += '.'
    }
    while (s.length <= rs + 1) {
      s += '0'
    }
    return parseFloat(s)
  }
  static regFenToYuan (fen) {
    let num = fen
    num = fen * 0.01
    num += ''
    const reg = num.indexOf('.') > -1 ? /(\d{1,3})(?=(?:\d{3})+\.)/g : /(\d{1,3})(?=(?:\d{3})+$)/g
    num = num.replace(reg, '$1')
    num = this.toDecimal2(num)
    return num
  }
  static getFullImgUrl (v) {
    const value = v || ''
    if (value.startsWith('http://') || value.startsWith('https://') || value.startsWith('data:image')) {
      return value
    }
    // return `${environment.baseUrl}/${value}`
  }
  static subLen (value, len) {
    if (value && value.length > len) {
      return `${value.substr(0, len)}......`
    }
    return value
  }
  static max (list) {
    return Math.max.apply(null, list)
  }
  static min (list) {
    return Math.min.apply(null, list)
  }
  static getFileNameWithoutExtension (filename) {
    return filename.substring(0, filename.indexOf("."));
  }

  static newGuid (len, radix) {
    let chars = '0123456789ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz'.split('');
    let uuid = [], i;
    radix = radix || chars.length;

    if (len) {
      // Compact form
      for (i = 0; i < len; i++) uuid[i] = chars[0 | Math.random() * radix];
    } else {
      // rfc4122, version 4 form
      let r;

      // rfc4122 requires these characters
      uuid[8] = uuid[13] = uuid[18] = uuid[23] = '-';
      uuid[14] = '4';

      // Fill in random data.  At i==19 set the high bits of clock sequence as
      // per rfc4122, sec. 4.1.5
      for (i = 0; i < 36; i++) {
        if (!uuid[i]) {
          r = 0 | Math.random() * 16;
          uuid[i] = chars[(i == 19) ? (r & 0x3) | 0x8 : r];
        }
      }
    }

    return uuid.join('');
  }
}
