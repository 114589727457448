<template>
    <div class="modal-container">
        <transition name="fade" @after-leave="afterLeave">
            <templateModal @close="close" :params="params" v-if="visiable"></templateModal>
        </transition>
    </div>
</template>

<script>
export default {
    name: "Modal",
    data() {
        return {
            visiable: false,
            params: {},
        };
    },
    methods: {
        close(p) {
            this.output = p;
            this.visiable = false;
        },
        afterLeave() {
            this.$emit("closed", this.output);
        },
    },
    components: {
        templateModal: null,
    },
};
</script>

<style lang="scss" scoped>
.modal-container {
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background: rgba(0, 0, 0, 0.5);
    .modal-wrapper {
        border-radius: 0.4rem;
        background: #ffffff;
        overflow: hidden;
        padding: 0.4rem;
        width: 80%;
    }
}
</style>
