<template>

  <transition
    name="select-item"
    @after-leave="afterLeave"
  >
  <div class="select-item-container" v-if="visiable"  :style="style">
    <div class="select-item-wrapper">
     <templateSelectItem  @close="close" :width="style.width" :items="items" :currentItem="currentItem"
        ></templateSelectItem>
    </div>
  </div>
  </transition>
</template>

<script>
export default {
  name: "SelectItem",
  props:{
    items:{
      type: Array,
      default: ()=>[],
    },
    currentItem:{
      type:String,
      default:''
    }
  },
  data() {
    return {
      visiable: false,
      output:null
    };
  },
  methods: {
    close (p) {
      this.output = p
      this.visiable = false
    },
    afterLeave () {
      this.$emit('closed', this.output)
    }
  }
};
</script>

<style lang="scss" scoped>
.select-item-container {
  position: fixed;
  display: flex;
  justify-content: center;
  .select-item-wrapper {
    flex: 1;
  }
}
</style>
