export default {
    currentScene: state => {
        if (!state.currentSceneId) return null;
        return state.config.scenes.find(o => o.sceneId == state.currentSceneId)
    },
    currentHotspot: (state,getters) => {
        if (!getters.currentScene) return null;
        if (!state.currentSceneHotspotId) return null;
        return getters.currentScene
            .hotspots.find(o => o.hsId == state.currentSceneHotspotId);
    },
    currentSceneHotspots:(state,getters)=>{
        if (!getters.currentScene) return [];
        return getters.currentScene
        .hotspots;
    }
}