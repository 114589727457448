<template>
    <div id="app">
        <!-- <canvas ref="canvasCloud" id="canvas-cloud"></canvas> -->
        <router-view />
    </div>
</template>
<script>
// import CloudOpen from "./views/webapp/pano/utils/cloud.js"
export default {
  mounted() {
    // let cloudOpen = new CloudOpen();
    // // let canvasCloud = this.$refs.canvasCloud;// document.getElementById("canvas-cloud");
    // let canvasCloud = document.getElementById("canvas-cloud");

    // console.log(canvasCloud)
    // try {
    //   cloudOpen.init(() => {
    //     cloudOpen.draw(() => {
    //       console.log("end....")
    //       canvasCloud.style.display = "none"
    //     });
    //   }, canvasCloud,
    //     "https://res.jzyglxt.com/app/shopUser/pub/cloud.png",
    //     20
    //   );
    // } catch (error) {
    //   console.log(error)
    // }
  }
}
</script>
<style lang="scss">
@font-face {
  font-family: youshe;
  src: url('./assets/font/YouSheBiaoTiHei-2.ttf');
}
#app {
  background: $color-background;
  display: flex;
  flex-direction: column;
  height: 100%;
  overflow: hidden;
  // background: url('./assets/cloud.png') no-repeat;
  background-size: 100% 100%;
}
#canvas-cloud {
  width: 100vw;
  height: 100vh;
  position: fixed;
  top: 0;
  left: 0;
  z-index: 9999;
  background: transparent;
}
</style>
