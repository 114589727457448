import BaseProp from '../components/edit-props/components/base-prop'
import ViewProp from '../components/edit-props/components/view-prop'
import HotspotProp from '../components/edit-props/components/hotspot-prop'
import LocationProp from '../components/edit-props/components/location-prop'
import MaskingProp from '../components/edit-props/components/masking-prop'
import EmbedProp from '../components/edit-props/components/embed-prop'
import MusicProp from '../components/edit-props/components/music-prop'
import SpecialProp from '../components/edit-props/components/special-prop'
import DetailsProp from '../components/edit-props/components/details-prop'
export default {
    leftNavs:[
        {
            icon:'&#xe62a;',
            name:'基础',
            prop:BaseProp
        },
        {
            icon:'&#xe63a;',
            name:'视角',
            prop:ViewProp
        },
        {
            icon:'&#xe67d;',
            name:'热点',
            prop:HotspotProp
        },
        {
            icon:'&#xe7e0;',
            name:'沙盘',
            prop:LocationProp
        },
        {
            icon:'&#xe691;',
            name:'遮罩',
            prop:MaskingProp
        },
        {
            icon:'&#xe6c4;',
            name:'嵌入',
            prop:EmbedProp
        },
        {
            icon:'&#xe7a6;',
            name:'音乐',
            prop:MusicProp
        },
        {
            icon:'&#xea1f;',
            name:'特效',
            prop:SpecialProp
        },
        {
            icon:'&#xeba2;',
            name:'细节',
            prop:DetailsProp
        }
    ]
}