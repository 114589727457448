import hotspot from "./hotspot"
import state from "./state"

export default {
    ADD_TAG: (state, param) => {
        state.tags.push(param)
    },
    OPEN_SLIDE: (state, slide) => {
        state.slide = slide
    },
    CLOSE_SLIDE: (state) => {
        state.slide = null
    },
    LEFT_NAV_CLICK: (state, index) => {
        state.leftNavIndex = index
    },
    INIT_EDIT: (state, kp) => {
        state.krpano = kp;
        //隐藏下方自带控制条
        kp.set('layer[skin_control_bar].visible', false);
        kp.set('layer[skin_splitter_bottom].visible', false);
        kp.set('layer[skin_scroll_window].visible', false);
    },
    INIT_HOTSPOT:(state,hotspot) =>{

    },
    DEL_HOTSPOT:(state,hotspot) =>{

    },
    ADD_HOTSPOT: (state, hotspot) => {
        const { iconUrl, hsId,size} = hotspot
        let panoScreen=document.querySelector("#pano");
        state.krpano.set("curscreen_x", panoScreen.clientWidth/2);
        state.krpano.set("curscreen_y", panoScreen.clientHeight/2);
        state.krpano.call("screentosphere(curscreen_x, curscreen_y, curscreen_ath, curscreen_atv);");
        let ath = state.krpano.get("curscreen_ath");
        let atv = state.krpano.get("curscreen_atv");
        // state.krpano.call('addSceneHotspot("'+iconUrl+'","'+ name +'",'+'1'+','+ath+','+atv+')');
        state.krpano.call(`addSceneHotspot('${iconUrl}','${hsId}','${ath}','${atv}','${size}')`);
        // let currentScene=state.config.scenes.find(o=>state.currentSceneId)
        // if(currentScene){
        //     let currentHotspot=currentScene.hotspots.find(o=>o.hsId==hsId)
        //     let newHotspot={hsId,iconId,iconUrl};
        //     if(!currentHotspot){
        //         currentScene.hotspots.push(newHotspot)
        //     }else{
        //         const index = currentScene.hotspots.findIndex(o=>o.hsId==hsId)
        //         Vue.set(currentScene.hotspots, index, {
        //             ...currentHotspot,...newHotspot
        //         })
        //     }
        //     state.currentSceneHotspotId=hsId;
        // }
        // param.ath = krpano.get("curscreen_ath");
        // param.atv = krpano.get("curscreen_atv");
        let scene=state.config.scenes.find(o=>o.sceneId==state.currentSceneId)
        if(scene){
            scene.hotspots.push(hotspot)
        }else{
            console.log('未找到对应的场景:'+state.currentSceneId)
        }
    },
    SET_CURRENT_HOTSPOT_ID:(state,hsId) =>{
        state.currentSceneHotspotId=hsId
    },
    MODIFY_HOTSPOT_ICON:(state,hotspot)=>{
        const { iconUrl,iconId, hsId} = hotspot
        state.krpano.call(`modifySceneHotspotIcon('${hsId}','${iconUrl}')`)
        // let scene=state.config.scenes.find(o=>o.sceneId==state.currentSceneId) 
        // if(scene){
        //     let oldHotspot=scene.hotspots.find(o=>o.hsId==hsId)
        //     if(oldHotspot){
        //         oldHotspot.iconId=iconId
        //         oldHotspot.iconUrl=iconUrl
        //     }
        // }else{
        //     console.log('未找到对应的场景:'+state.currentSceneId)
        // }
    },
    MODIFY_HOTSPOT_SIZE:(state,hotspot)=>{
        const { size, hsId} = hotspot
        state.krpano.call(`modifySceneHotspotSize('${hsId}','${size}')`)
    },
    MODIFY_HOTSPOT_TITLE:(state,hotspot)=>{
        const { hsId,title,fontSize} = hotspot
        state.krpano.call(`modifySceneHotspotTitle('${hsId}','${title}','${fontSize}')`)
    },
    DELETE_HOTSPOT:(state,hotspot)=>{
        state.krpano.call('removehotspot('+hotspot.hsId+');');
        state.krpano.call('removeplugin(' + ('tooltip_' + hotspot.hsId) + ',true);');
        let scene=state.config.scenes.find(o=>o.sceneId==hotspot.sceneId) 
            let index=scene.hotspots.findIndex(o=>o.hsId==hotspot.hsId)
            if(index>=0){
                scene.hotspots.splice(index, 1)
            }
        }
}