
import { upload,publish,cutComplete } from "api/my/publish";
import Util from "common/js/util";
export default {
    selectImg:  ({ commit }, imgEntry) => {
        commit('SELECT_IMG', imgEntry)
        const img = { ...imgEntry };
        const formData = new FormData();
        const blob = Util.dataURItoBlob(img.base64);
        formData.append("file", blob, img.fullName);
        return new Promise((resolve, reject) => {
            upload(formData).then(o => {
                img.serverId = o
                img.status = 1
                commit('UPLOAD_IMG', img)
                resolve(o)
            }).catch(error => {
                commit('REMOVE_IMG', img.id)
                reject(error)
            })
        })
    },
    removeImg:({commit},id)=>{
        commit('REMOVE_IMG', id)
    },
    setTitle:({commit},title)=>{
        commit('SET_TITLE',title)
    },
    publish:({commit},projectInfo)=>{
        return new Promise((resolve, reject) => {
            publish(projectInfo).then(o => {
                commit('PUBLISH_SUCCESS')
                resolve(o)
            }).catch(error => {
                reject(error)
            })
        })
    },
    queryImageCutComplete:({commit},materialIds)=>{
        return new Promise((resolve, reject) => {
            cutComplete(materialIds).then(o => {
                commit('CUT_COMPLETE',o)
                resolve(o)
            }).catch(error => {
                reject(error)
            })
        })
    },
    // addHospot:({commit},hotspot)=>{

    // }

}