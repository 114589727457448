<template>
  <div class="base-props-container">
    <div class="base-props-title">作品二维码</div>
    <img
      class="base-props-image"
      src="@/assets/qrcode.jpeg"
      alt=""
    >
    <div class="base-props-reselect-container">
      <div class="base-props-reselect-tip">
        <div>自定义LOGO</div>
        <div>120 x 120</div>
      </div>
      <pano-button
        class="base-props-reselect-button"
        name="选择图片"
      ></pano-button>
    </div>
    <div class="base-props-title-second-container">
    <div class="base-props-title">作品地址</div>
    <a href="">查看</a>
    </div>
    <div class="base-props-addr-container">
      http://www.baidu.com/a/a/a/a/a/a/a/a
    </div>
  </div>
</template>
<script>
export default {

}
</script>
<style lang="scss">
.base-props-container {
  padding: 20px;
  .base-props-title {
    font-size: $font-size-medium;
    color: #ffffff;
    margin-bottom: 10px;
  }
  .base-props-image {
    width: 100%;
    margin-bottom: 10px;
  }
  .base-props-reselect-container {
    display: flex;
    padding-bottom: 40px;
    border-bottom: 1px solid #000000;
    .base-props-reselect-tip {
      line-height: 15px;
      font-size: $font-size-small;
      flex: 1;
    }
    .base-props-reselect-button {
      width: 80px;
    }
  }
  .base-props-title-second-container{
    padding-top: 40px;
    border-top: 1px solid #4b4b4b;
    display: flex;
    justify-content: space-between;
    a{
      color: $color-theme;
    }

  }
  .base-props-addr-container {
    border: 1px solid #4b4b4b;
    padding: 15px;
    overflow: scroll;
    user-select: text;
  }
  .base-props-addr-container::-webkit-scrollbar {
    display: none;
  }
}
</style>