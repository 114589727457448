import ProjectInfo from '../components/nav-right-slide/components/project-info'
export default{
    mainMenus:[
        {
            icon:'icon-caidan',
            name:'基础设置'
        },
        {
            icon:'icon-ziyuan',
            name:'视角'
        },
        {
            icon:'icon-local',
            name:'热点'
        },
    ],
    baseMenus:[
        {
            icon:'icon-info',
            name:'作品信息',
            props:ProjectInfo,
            openType:1//1 full 2 bottom
        },
        {
            icon:'icon-juxingxuanze',
            name:'品牌LOGO',
            props:null,
            openType:1//1 full 2 bottom
        },
        {
            icon:'icon-atom-solid',
            name:'地面遮罩',
            props:null,
            openType:1//1 full 2 bottom
        },
        {
            icon:'icon-guanggaoguanli',
            name:'开场广告',
            props:null,
            openType:1//1 full 2 bottom
        },
        {
            icon:'icon-music',
            name:'背景音乐',
            props:null,
            openType:1//1 full 2 bottom
        },
        {
            icon:'icon-gundongwenzi',
            name:'字幕滚动',
            props:null,
            openType:1//1 full 2 bottom
        }
    ]
}