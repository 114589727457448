export default{
    resetState: ({ commit }) => {
        commit("RESET_STATE")
    },
    initKrpano: ({ commit }, params) => {
        commit("INIT_KRPANO", params)
    },
    onMainMenuClickHandle: ({ commit }, param) => {
        commit("ON_MAIN_MENU_CLICK_HANDLE", param)
    },
    onSlideClickHandle: ({ commit }, slide) => {
        if(slide.openType==1){
            commit("ON_RIGHT_SLIDE_OPEN", slide)
        } if(slide.openType==2){
            commit("ON_BOTTOM_SLIDE_OPEN", slide)
        }
    },
    onSlideClose: ({ commit }) => {
        commit("ON_SLIDE_CLOSE")
    },
    onSceneSelect: ({ commit },sceneId) => {
        commit("ON_SCENE_SELECT",sceneId)
    },
    onHotspotToggle: ({ commit }, hotspotId) => {
        commit("ON_HOTSPOT_TOGGLE", hotspotId)
    },
    onHotspotAdd:({commit},hotspot)=>{
        commit('ON_HOTSPOT_ADD',hotspot)
    },
    onHotspotModify:({commit},hotspot)=>{
        commit('ON_HOTSPOT_MODIFY',hotspot)
    },
    onHotspotIconModify:({commit},hotspot)=>{
        commit('ON_HOTSPOT_ICON_MODIFY',hotspot)
    },
    onHotspotNameModify:({commit},hotspot)=>{
        commit('ON_HOTSPOT_NAME_MODIFY',hotspot)
    },
    lookToHotspot: ({ commit }, hotspotId) => {
        commit("LOOK_TO_HOTSPOT", hotspotId)
    },
    onHotspotClickFocus:({commit},hotspotId)=>{
        commit('ON_HOTSPOT_CLICK_FOCUS',hotspotId)
    },
    onHotspotRemove:({commit},hotspot)=>{
        commit('ON_HOTSPOT_REMOVE',hotspot)
    },
    onProjectNameModify:({commit},name)=>{
        commit('ON_PROJECT_NAME_MODIFY',name)
    },
    onProjectCoverModify:({commit},cover)=>{
        commit('ON_PROJECT_COVER_MODIFY',cover)
    },
    onProjectDescModify:({commit},desc)=>{
        commit('ON_PROJECT_DESC_MODIFY',desc)
    }
}