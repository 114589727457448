<template>
  <div class="fov-set-container">
    <div class="fov-set-container">视角（FOV）范围设置</div>
    <slider-fov ref="fov"  @leftHandle="fovLeft"  @centerHandle="fovCenter" @rightHandle="fovRight" :initMin="min" :initMax="max" :initValue="current"  ></slider-fov>
    <div class="fov-set-input-container">
      <div class="fov-set-input-value">
        <pano-slider-input v-model="min" @blur="fovLeftBlur"></pano-slider-input>
        <div class="fov-set-label">最近</div>
      </div>
      <div class="fov-set-input-value">
        <pano-slider-input v-model="current" @blur="fovCenterBlur"></pano-slider-input>
        <div class="fov-set-label">初始</div>
      </div>
      <div class="fov-set-input-value">
        <pano-slider-input v-model="max" @blur="fovRightBlur"></pano-slider-input>
        <div class="fov-set-label">最远</div>
      </div>
    </div>
  </div>
</template>
<script>
import PanoSliderInput from "../pano-slider-input";
import SliderFov from "../slider-fov";
import Util from "@/common/js/util"
export default {
  data() {
    return {
      min: 0.1,
      max: 100,
      current: 50,
    };
  },
  methods: {
    fovLeft(v) {
      this.min = Util.toDecimal1(v);
    },
    fovRight(v) {
      this.max = Util.toDecimal1(v);
    },
    fovCenter(v) {
      this.current = Util.toDecimal1(v);
    },
    fovLeftBlur(v){
      if(v<0.1)
      {
        v=0.1
      } else if(v>this.current){
        v=this.current
      }
      this.min=v
      this.$refs.fov.setFov(this.min,this.current,this.max)
    },
    fovCenterBlur(v){
      if(v<this.min)
      {
        v=this.min
      } else if(v>this.max){
        v=this.max
      }
      this.current=v
      this.$refs.fov.setFov(this.min,this.current,this.max)
    },
    fovRightBlur(v){
      if(v<this.current)
      {
        v=this.current
      } else if(v>179){
        v=179
      }
      this.max=v
      this.$refs.fov.setFov(this.min,this.current,this.max)
    }
  },
  components: {
    SliderFov,
    PanoSliderInput,
  },
};
</script>
<style lang="scss">
.fov-set-container {
  .fov-set-container {
    margin-bottom: 20px;
    color: #ffffff;
  }
  .fov-set-input-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .fov-set-input-value {
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .fov-set-label {
        margin-top: 5px;
        font-size: $font-size-small;
        color: #999999;
      }
    }
  }
}
</style>