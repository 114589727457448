<template>
  <div class="slider-vertical-container">
    <div class="slider-vertical-value-container" ref="mySlider">
      <div
        class="slider-vertical-value"
        :style="{ width: `${vWidth}%`, left: `${left.left}%` }"
      ></div>
    </div>
    <div
      class="slider-vertical-left"
      :style="{ left: `${left.left}%` }"
      ref="sliderLeft"
    >
      <img src="@/assets/slider_bound.png" alt="" />
    </div>

    <div
      class="slider-vertical-right"
      :style="{ left: `${right.left}%` }"
      ref="sliderRight"
    >
      <img src="@/assets/slider_bound.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
      min:{
      type: Number,
      default:0,
      },
      max:{
      type: Number,
      default:0,
      },
    currentMin: {
      type:  Number,
      default: 0,
    },
    currentMax: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      startMove: false,
      left: {
        left: 0,
        value: 0,
      },
      right: {
        left: 100,
        value: 0,
      },
    };
  },
  watch: {},
  computed: {
    mySlider() {
      return this.$refs.mySlider;
    },
    sliderLeft() {
      return this.$refs.sliderLeft;
    },
    sliderRight() {
      return this.$refs.sliderRight;
    },
    vWidth() {
      return this.right.left - this.left.left;
    },
    mySliderX() {
      return this.elementLeft(this.mySlider);
    },
    paddingMin (){
        return 0 - this.min;
    }
  },
  mounted() {
    this.left.left = this._valueToPercent(this.currentMin);
    this.right.left = this._valueToPercent(this.currentMax);

    this.listenSliderEvent(this.sliderLeft, 0);
    this.listenSliderEvent(this.sliderRight, 2);
  },
  methods: {
    _valueToPercent(v) {
      return ((v + this.paddingMin ) / (this.max + this.paddingMin )) * 100;
    },
    elementLeft(e) {
      //计算x坐标
      var offset = e.offsetLeft;
      if (e.offsetParent != null) offset += this.elementLeft(e.offsetParent);
      return offset;
    },
    listenSliderEvent(el, leftOrRight) {
      let elData;
      let calcMax;
      let calcMin;
      let outHandle;
      if (leftOrRight == 0) {
        elData = this.left;
        calcMax = () => {
          return this.right.left;
        };
        calcMin = () => {
          return 0;
        };
        outHandle = () => {
          const v = (this.max + this.paddingMin ) * this.left.left * 0.01 - this.paddingMin ;
          this.$emit("leftHandle", v);
        };
      } else {
        elData = this.right;
        calcMax = () => {
          return 100;
        };
        calcMin = () => {
          return this.left.left;
        };
        outHandle = () => {
          const v = (this.max + this.paddingMin) * this.right.left * 0.01 - this.paddingMin ;
          this.$emit("rightHandle", v);
        };
      }

      const mouseMoveHandle = (e) => {
        if (!this.startMove) return;
        const pageX = e.pageX - this.mySliderX; //获取滑动x坐标
        let leftp = (pageX / this.mySlider.offsetWidth) * 100; //计算百分比
        const max = calcMax();
        const min = calcMin();
        if (leftp > max) {
          //判断不超出范围
          leftp = max;
        } else if (leftp < min) {
          leftp = min;
        }
        elData.left = leftp;

        outHandle();
      };

      //注册docuemnt的鼠标mouseup
      const mouseUpHanlde = () => {
        if (this.startMove) {
          this.startMove = false;
          document.removeEventListener("mousemove", mouseMoveHandle);
          document.removeEventListener("mouseup", mouseUpHanlde);
        }
      };
      el.addEventListener("mousedown", (e) => {
        this.startMove = true;
        document.addEventListener("mousemove", mouseMoveHandle);
        document.addEventListener("mouseup", mouseUpHanlde);

        e.preventDefault();
      });
    },
    setFov(left, right) {
      this.left.left = this._valueToPercent(parseFloat(left));
      this.right.left = this._valueToPercent(parseFloat(right));
    },
  },
};
</script>
<style lang="scss">
.slider-vertical-container {
  position: relative;
  margin: 0 5px;
  .slider-vertical-value-container {
    border: 1px solid #4b4b4b;
    height: 2px;
    width: 100%;
    position: relative;
    .slider-vertical-value {
      position: absolute;
      top: 0;
      height: 100%;
      background: $color-theme;
    }
  }

  .slider-vertical-left,
  .slider-vertical-right {
    position: absolute;
    img {
      position: absolute;
      cursor: pointer;
      width: 10px;
      top: -9px;
      left: -5px;
    }
  }
  .slider-vertical-right {
    left: 100%;
  }
}
</style>