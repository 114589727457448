<template>
  <transition
    name="fade"
    @after-leave="afterLeave"
  >
    <div
      class="alert-container"
      :style="style"
      v-show="visiable"
    >
      <div class="alert-wrapper">
        <div class="title-container ellipsis">{{title}}</div>
        <div class="content-container ellipsis5">{{content}}</div>
        <div
          class="close-contaner"
          @click="handleClose"
        >{{btn}}</div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Alert',
  props: {
    title: {
      type: String,
      default: '提示!'
    },
    content: {
      type: String,
      required: true
    },
    btn: {
      type: String,
      default: '确定'
    }
  },
  data () {
    return {
      visiable: false
    }
  },
  computed: {
    style () {
      return {}
    }
  },
  methods: {
    handleClose (e) {
      e.preventDefault()
      this.$emit('close')
    },
    afterLeave () {
      this.$emit('closed')
    }
  }
}
</script>

<style lang="scss" scoped>
.alert-container {
  position: fixed;
  background: rgba(0, 0, 0, 0.5);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  .alert-wrapper {
    background: #ffffff;
    border-radius: 0.4rem;
    width: 5rem;
    .title-container {
      padding: 0.4rem 0.4rem 0 0.4rem;
      text-align: center;
      line-height: 0.4rem;
      font-size: 0.36rem;
      font-weight: 500;
      margin-bottom: 0.2rem;
    }
    .content-container {
      padding: 0 0.4rem 0 0.4rem;
      text-align: center;
      line-height: 0.4rem;
      font-size: 0.28rem;
      margin-bottom: 0.2rem;
    }
    .close-contaner {
      padding: 0.3rem 0.4rem;
      color: $pano-theme;
      border-top: 0.02rem solid #dddddd;
      font-size: 0.32rem;
      text-align: center;
      transition: all 0.5s;
    }
    .close-contaner:active {
      background: rgba(0, 0, 0, 0.3);
    }
  }
}
</style>
