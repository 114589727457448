import Menus from "./menus"

export default {
    xml:null,
    krpano: null,
    mainMenus: [...Menus.mainMenus],
    currentMenusIndex: 0,
    baseMenus: [...Menus.baseMenus],
    rightSlide: null,
    bottomSlide: null,
    currentLayoutIndex: 0,
    id:null,
    name:'',
    description:null,
    cover:'/panos/huiyishi.tiles/thumb.jpg',
    scenes: [],
    currentSceneId: '',
    currentHotspotId: null,
    hotspotIcons: [
        {
            id: "1",
            icon: "/static/def-material/hot-spots/book_animation.png",
            iconThumb: "/static/def-material/hot-spots/book_thumb.png",
        },
        {
            id: "2",
            icon: "/static/def-material/hot-spots/food_animation.png",
            iconThumb: "/static/def-material/hot-spots/food_thumb.png",
        },
        {
            id: "3",
            icon: "/static/def-material/hot-spots/house_animation.png",
            iconThumb: "/static/def-material/hot-spots/house_thumb.png",
        },
        {
            id: "4",
            icon: "/static/def-material/hot-spots/scene_animation.png",
            iconThumb: "/static/def-material/hot-spots/scene_thumb.png",
        },
        {
            id: "5",
            icon: "/static/def-material/hot-spots/top_straight_animation.png",
            iconThumb: "/static/def-material/hot-spots/top_straight_thumb.png",
        },
        {
            id: "6",
            icon: "/static/def-material/hot-spots/top_left_animation.png",
            iconThumb: "/static/def-material/hot-spots/top_left_thumb.png",
        },
        {
            id: "7",
            icon: "/static/def-material/hot-spots/top_right_animation.png",
            iconThumb: "/static/def-material/hot-spots/top_right_thumb.png",
        },
        {
            id: "8",
            icon: "/static/def-material/hot-spots/left_animation.png",
            iconThumb: "/static/def-material/hot-spots/left_thumb.png",
        },
        {
            id: "9",
            icon: "/static/def-material/hot-spots/right_animation.png",
            iconThumb: "/static/def-material/hot-spots/right_thumb.png",
        },
        {
            id: "10",
            icon: "/static/def-material/hot-spots/zsj_animation.png",
            iconThumb: "/static/def-material/hot-spots/zsj_thumb.png",
        },
        {
            id: "11",
            icon: "/static/def-material/hot-spots/play_animation.png",
            iconThumb: "/static/def-material/hot-spots/play_thumb.png",
        },
        {
            id: "12",
            icon: "/static/def-material/hot-spots/finger_click_animation.png",
            iconThumb: "/static/def-material/hot-spots/finger_click_thumb.png",
        },
        {
            id: "13",
            icon: "/static/def-material/hot-spots/speak_animation.png",
            iconThumb: "/static/def-material/hot-spots/speak_thumb.png",
        }
    ]
}


        // {
        //     id: 'scene_huiyishi',
        //     name: 'scene_huiyishi',
        //     thumb: '/panos/huiyishi.tiles/thumb.jpg',
        //     name: 'huiyishi',
        //     hotspots: [
        //         {
        //             id: 'hotspot_123',
        //             hotspotType: 1,
        //             icon: '/static/def-material/hot-spots/spot_13.png',
        //             iconThumb: '/static/def-material/hot-spots/spot_13.png',
        //             iconId: '1',
        //             sceneId: 'scene_huiyishi',
        //             size: 50,
        //             fontSize: 12,
        //             name: "未命名11",
        //             ath: "-17.90926464920858",
        //             atv: "12.793247278900218",
        //             toSceneId: null,
        //             toSceneVLookat: 0,
        //             toSceneHLookat: 0,
        //         }
        //     ]
        // },
        // {
        //     id: 'scene_jishubu',
        //     name: 'scene_jishubu',
        //     thumb: '/panos/jishubu.tiles/thumb.jpg',
        //     name: 'jishubu',
        //     hotspots: [
        //     ]
        // },
        // {
        //     id: 'scene_zoulang',
        //     name: 'scene_zoulang',
        //     thumb: '/panos/zoulang.tiles/thumb.jpg',
        //     name: 'zoulang',
        //     hotspots: [
        //     ]
        // }