<template>
  <div class="slider-size-container">
    <div
      class="slider-value-container"
      ref="mySlider"
    >
      <div
        class="slider-value"
        :style="{ width: `${vWidth}%` }"
      ></div>
    </div>

    <div
      class="slider-right-container"
      :style="{ left: `${right.left}%` }"
      ref="sliderRight"
    >
      <div class="slider-right"></div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    min: {
      type:  Number,
      default: 0,
    },
    max: {
      type:  Number,
      default: 2,
    },
    initValue: {
      type:  Number,
      default: 1,
    },
  },
  data () {
    return {
      startMove: false,
      fps60: false,
      right: {
        left: 50,
        value: 0,
      },
    };
  },
  watch: {},
  computed: {
    mySlider () {
      return this.$refs.mySlider;
    },
    sliderRight () {
      return this.$refs.sliderRight;
    },
    vWidth () {
      return this.right.left;
    },
    mySliderX () {
      return this.elementLeft(this.mySlider);
    },
  },
  mounted () {
    this.right.left = (this.initValue / this.max) * 100;

    this.listenSliderEvent(this.sliderRight);
  },
  methods: {
    elementLeft (e) {
      //计算x坐标
      var offset = e.offsetLeft;
      if (e.offsetParent != null) offset += this.elementLeft(e.offsetParent);
      return offset;
    },
    listenSliderEvent (el) {
      let elData;
      let calcMax;
      let calcMin;
      let outHandle;

      elData = this.right;
      calcMax = () => {
        return 100;
      };
      calcMin = () => {
        return this.min;
      };
      outHandle = () => {
        const v = (this.max) * this.right.left * 0.01;
        if (!this.fps60) {
          this.fps60 = true;
          this.$emit("rightHandle", v);
          setTimeout(() => {
            this.fps60 = false;
          }, 1000 / 60);
        }
      };

      const mouseMoveHandle = (e) => {
        if (!this.startMove) return;
        const pageX = e.pageX - this.mySliderX; //获取滑动x坐标
        let leftp = (pageX / this.mySlider.offsetWidth) * 100; //计算百分比
        const max = calcMax();
        const min = calcMin();
        if (leftp > max) {
          //判断不超出范围
          leftp = max;
        } else if (leftp < min) {
          leftp = min;
        }
        elData.left = leftp;

        outHandle();
      };

      //注册docuemnt的鼠标mouseup
      const mouseUpHanlde = () => {
        if (this.startMove) {
          this.startMove = false;
          document.removeEventListener("mousemove", mouseMoveHandle);
          document.removeEventListener("mouseup", mouseUpHanlde);
        }
      };
      el.addEventListener("mousedown", (e) => {
        this.startMove = true;
        document.addEventListener("mousemove", mouseMoveHandle);
        document.addEventListener("mouseup", mouseUpHanlde);

        e.preventDefault();
      });
    },
  },
};
</script>
<style lang="scss">
.slider-size-container {
  position: relative;
  .slider-value-container {
    border: 1px solid #4b4b4b;
    height: 2px;
    width: 100%;
    position: relative;
    .slider-value {
      position: absolute;
      top: 0;
      height: 100%;
      background: $color-theme;
    }
  }

  .slider-right-container {
    position: absolute;
    z-index: 1;
    left: 100%;
    .slider-right {
      position: absolute;
      cursor: pointer;
      height: 22px;
      width: 22px;
      top: -13px;
      left: -11px;
      border-radius: 50%;
      background: #eeeeee;
      border: 1px solid #999999;
      box-sizing: border-box;
      opacity: 0.9;
      box-shadow: -3px 3px 6px 2px rgba(0, 0, 0, 0.5);
    }
  }
  // .grab {
  //   /* 抓抓抓 */
  //   cursor: -webkit-grab;
  //   cursor: -moz-grab;
  //   cursor: grab;
  // }
  // .grabbing {
  //   /* 抓住抓住 */
  //   cursor: -webkit-grabbing;
  //   cursor: -moz-grabbing;
  //   cursor: grabbing;
  // }
}
</style>