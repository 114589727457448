<template>
    <div class="hotspot-edit-title-container">
        <span>{{name}}</span><i class="iconfont" @click="close()">&#xe6dd;</i>
    </div>
</template>
<script>
import { mapActions } from 'vuex';
export default {
    props:{
        name:{
            type:String,
            default:''
        }
    },
    methods:{
        ...mapActions('editModule',['closeSlide']),
        close(){
            this.closeSlide();
        }
    }
}
</script>
<style lang="scss">
    .hotspot-edit-title-container{
        height: 40px;
        min-height: 40px;
        padding: 0 15px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        color: #ffffff;
        border-bottom: 1px solid #222222;
        font-size: $font-size-medium;
        .iconfont{
            cursor: pointer;
        }
    }
</style>