<template>
<input
      class="pano-input"
      type="text"
      :value="value"
      :placeholder="placeholder"
      @input="$emit('input', $event.target.value)"
    />
</template>

<script>
export default {
  props: {
    value: {
      type: String,
    },
    label: {
      type: String,
    },
    placeholder: {
      type: String,
      default: "",
    },
  },
  methods: {},
};
</script>

<style lang="scss" scoped>
.pano-input {
  border: 1px solid #a8a8a8;
  border-radius: 4px;
  width: 100%;
  line-height: 36px;
  padding: 0 10px;
  box-sizing: border-box;
  transition: all 0.3s ease-in-out;
}
.pano-input:hover {
  border: 1px solid $color-theme;
}
</style>
