export default {
    currentIsBaseMainMenu: state => {
        return state.currentMenusIndex == 0;
    },
    currentIsFovMainMenu: state => {
        return state.currentMenusIndex == 1;
    },
    currentIsHotspotMainMenu: state => {
        return state.currentMenusIndex == 2;
    },
    noLayoutOn: state => {
        return state.currentLayoutIndex == 0;
    },
    currentScene: state => {
        if (state.currentSceneId) {
            return state.scenes.find(o => o.id == state.currentSceneId)
        }
        return null;
    },
    currentHotspot: (state,getters) => {
        if (getters.currentScene&&state.currentHotspotId) {
            return getters.currentScene.hotspots.find(o => o.id == state.currentHotspotId)
        }
        return null;
    },
    currentHotspotSceneId: (state,getters) => {
        if (getters.currentScene&&state.currentHotspotId) {
            let hotspot=getters.currentScene.hotspots.find(o => o.id == state.currentHotspotId)
            if(hotspot){
                return hotspot.sceneId;
            }
        }
        return null;
    },
    myProject:(state,getters)=>{
      let project=  {
            id:state.id,
            name:state.name,
            cover:state.cover,
            description:state.description,
            scenes:[...state.scenes]
        }
        return project;
    }
}