<template>
  <div class="hotspot-edit-sub-title-container">
    {{name}}
  </div>
</template>
<script>
export default {
  props:{
    name:{
      type:String,
      default:''
    }
  }
}
</script>
<style lang="scss">
  .hotspot-edit-sub-title-container{
    padding: 0 15px;
    height: 40px;
    display: flex;
    align-items: center;
    color: #ffffff;
    background: linear-gradient(90deg,rgba(255, 255, 255, 0.39) 4%,hsla(0,0%,100%,0) 98%);
  }
</style>