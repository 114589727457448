<template>
  <div class="slider-container">
    <div class="slider-value-container" ref="mySlider">
      <div
        class="slider-value"
        :style="{ width: `${vWidth}%`, left: `${left.left}%` }"
      ></div>
    </div>
    <div
      class="slider-left"
      :style="{ left: `${left.left}%` }"
      ref="sliderLeft"
    >
      <img src="@/assets/slider_bound.png" alt="" />
    </div>
    <div
      class="slider-center-container"
      :style="{ left: `${center.left}%` }"
      ref="sliderCenter"
    >
      <div class="slider-center"></div>
    </div>

    <div
      class="slider-right"
      :style="{ left: `${right.left}%` }"
      ref="sliderRight"
    >
      <img src="@/assets/slider_bound.png" alt="" />
    </div>
  </div>
</template>
<script>
export default {
  props: {
    min: {
      type: String | Number,
      default: 0.1,
    },
    max: {
      type: String | Number,
      default: 179,
    },
    initMin: {
      type: String | Number,
      default: 0,
    },
    initMax: {
      type: String | Number,
      default: 100,
    },
    initValue: {
      type: String | Number,
      default: 50,
    },
  },
  data() {
    return {
      startMove: false,
      left: {
        left: 0,
        value: 0,
      },
      center: {
        left: 50,
        value: 0,
      },
      right: {
        left: 100,
        value: 0,
      },
    };
  },
  watch: {},
  computed: {
    mySlider() {
      return this.$refs.mySlider;
    },
    sliderLeft() {
      return this.$refs.sliderLeft;
    },
    sliderCenter() {
      return this.$refs.sliderCenter;
    },
    sliderRight() {
      return this.$refs.sliderRight;
    },
    vWidth() {
      return this.right.left - this.left.left;
    },
    mySliderX() {
      return this.elementLeft(this.mySlider);
    },
  },
  mounted() {
    this.left.left = (this.initMin / this.max) * 100;
    this.right.left = (this.initMax / this.max) * 100;
    this.center.left = (this.initValue / this.max) * 100;

    this.listenSliderEvent(this.sliderLeft, 0);
    this.listenSliderEvent(this.sliderCenter, 1);
    this.listenSliderEvent(this.sliderRight, 2);
  },
  methods: {
    elementLeft(e) {
      //计算x坐标
      var offset = e.offsetLeft;
      if (e.offsetParent != null) offset += this.elementLeft(e.offsetParent);
      return offset;
    },
    listenSliderEvent(el, leftOrRight) {
      let elData;
      let calcMax;
      let calcMin;
      let outHandle;
      if (leftOrRight == 0) {
        elData = this.left;
        calcMax = () => {
          return this.center.left;
        };
        calcMin = () => {
          return 0;
        };
        outHandle = () => {
          const v = (this.max - 0.1) * this.left.left * 0.01 + 0.1;
          this.$emit("leftHandle", v);
        };
      } else if (leftOrRight == 1) {
        elData = this.center;
        calcMax = () => {
          return this.right.left;
        };
        calcMin = () => {
          return this.left.left;
        };
        outHandle = () => {
          const v = (this.max - 0.1) * this.center.left * 0.01 + 0.1;
          this.$emit("centerHandle", v);
        };
      } else {
        elData = this.right;
        calcMax = () => {
          return 100;
        };
        calcMin = () => {
          return this.center.left;
        };
        outHandle = () => {
          const v = (this.max - 0.1) * this.right.left * 0.01 + 0.1;
          this.$emit("rightHandle", v);
        };
      }

      const mouseMoveHandle = (e) => {
        if (!this.startMove) return;
        const pageX = e.pageX - this.mySliderX; //获取滑动x坐标
        let leftp = (pageX / this.mySlider.offsetWidth) * 100; //计算百分比
        const max = calcMax();
        const min = calcMin();
        if (leftp > max) {
          //判断不超出范围
          leftp = max;
        } else if (leftp < min) {
          leftp = min;
        }
        elData.left = leftp;

        outHandle();
      };

      //注册docuemnt的鼠标mouseup
      const mouseUpHanlde = () => {
        if (this.startMove) {
          this.startMove = false;
          document.removeEventListener("mousemove", mouseMoveHandle);
          document.removeEventListener("mouseup", mouseUpHanlde);
        }
      };
      el.addEventListener("mousedown", (e) => {
        this.startMove = true;
        document.addEventListener("mousemove", mouseMoveHandle);
        document.addEventListener("mouseup", mouseUpHanlde);

        e.preventDefault();
      });
    },
    setFov(left, center, right) {
      this.left.left = (left / this.max) * 100;
      this.center.left = (center / this.max) * 100;
      this.right.left = (right / this.max) * 100;
    },
  },
};
</script>
<style lang="scss">
.slider-container {
  position: relative;
  margin: 0 5px;
  .slider-value-container {
    border: 1px solid #4b4b4b;
    height: 2px;
    width: 100%;
    position: relative;
    .slider-value {
      position: absolute;
      top: 0;
      height: 100%;
      background: $color-theme;
    }
  }

  .slider-left,
  .slider-right {
    position: absolute;
    img {
      position: absolute;
      cursor: pointer;
      width: 10px;
      top: -9px;
      left: -5px;
    }
  }
  .slider-right {
    left: 100%;
  }
  .slider-center-container {
    position: absolute;
    z-index: 1;
    .slider-center {
      position: absolute;
      cursor: pointer;
      height: 22px;
      width: 22px;
      top: -13px;
      left: -11px;
      border-radius: 50%;
      background: #eeeeee;
      border: 1px solid #999999;
      box-sizing: border-box;
      opacity: 0.9;
      box-shadow: -3px 3px 6px 2px rgba(0, 0, 0, 0.5);
    }
  }
  // .grab {
  //   /* 抓抓抓 */
  //   cursor: -webkit-grab;
  //   cursor: -moz-grab;
  //   cursor: grab;
  // }
  // .grabbing {
  //   /* 抓住抓住 */
  //   cursor: -webkit-grabbing;
  //   cursor: -moz-grabbing;
  //   cursor: grabbing;
  // }
}
</style>