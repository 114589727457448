<template>
  <div class="prop-scroll-container">
    <slot></slot>
  </div>
</template>
<script>
export default {};
</script>
<style lang="scss">
.prop-scroll-container {
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: auto;
  border-bottom: 1px solid #222222;
}
.prop-scroll-container::-webkit-scrollbar {
  display: none;
}
</style>