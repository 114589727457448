import { initKrpano,hiddenTool, loadScene,loadHotspotWithThumb, addHotspot,modifyHostpotIconThumb, modifyHostpotIcon, modifySceneHotspotName, lookToHotspot,removeHotspot } from '@/utils/krpanoUtil'
import Vue from 'vue'
export default {
    INIT_KRPANO: (state, params) => {

        let project=params.project
        let scenes=params.scenes
        state.krpano = params.kp
        initKrpano(state.krpano)
        
        state.id=project.id
        state.xml=project.xml
        state.name=project.name
        state.cover=project.cover
        state.description=project.description
        scenes.forEach(scene=>{
            state.scenes.push(scene)
        })
        state.currentSceneId=scenes[0].id
        //隐藏下方自带控制条
        setTimeout(() => {
            hiddenTool(state.krpano);
            if (state.scenes.length > 0) {
                let hotspots = state.scenes[0].hotspots
                if (hotspots.length > 0) {
                    hotspots.forEach(hotspot => {
                        loadHotspotWithThumb(state.krpano, hotspot)
                        modifySceneHotspotName(state.krpano, hotspot)
                    });
                }
            }

        }, 300);
    },
    ON_MAIN_MENU_CLICK_HANDLE: (state, index) => {
        state.currentMenusIndex = index
    },
    ON_RIGHT_SLIDE_OPEN: (state, slide) => {
        state.rightSlide = slide
        state.currentLayoutIndex++;
    },
    ON_BOTTOM_SLIDE_OPEN: (state, slide) => {
        state.bottomSlide = slide
        state.currentLayoutIndex++;
    },
    ON_SLIDE_CLOSE: (state) => {
        state.currentLayoutIndex--;
        state.rightSlide = null
        state.bottomSlide = null
    },
    ON_SCENE_SELECT: (state, sceneId) => {
        state.currentSceneId = sceneId
        loadScene(state.krpano, sceneId)
        let scene=state.scenes.find(o=>o.id==sceneId)
        if(scene&&scene.hotspots.length>0){
            scene.hotspots.forEach(hotspot => {
                loadHotspotWithThumb(state.krpano, hotspot)
                modifySceneHotspotName(state.krpano, hotspot)
            });
        }
    },
    ON_HOTSPOT_TOGGLE: (state, hotspotId) => {
        if (state.currentHotspotId != hotspotId) {
            state.currentHotspotId = hotspotId
        } else {
            state.currentHotspotId = null
        }
    },
    ON_HOTSPOT_ADD: (state, hotspot) => {
        let hs = addHotspot(state.krpano, hotspot)
        let scene = state.scenes.find(o => o.id == hs.sceneId)
        if (scene) {
            scene.hotspots.push(hs)
        } else {
            console.log('未找到对应的场景:' + state.currentSceneId)
        }
    },
    ON_HOTSPOT_MODIFY: (state, hotspot) => {
        let scene = state.scenes.find(o => o.id == hotspot.sceneId)
        if (scene) {
           
          const index = scene.hotspots.findIndex(o=>o.id==hotspot.id)
          if(index>=0){
            let h=scene.hotspots.find(o=>o.id==hotspot.id)
           
            Vue.set(scene.hotspots, index, {
                ...h,...hotspot
            })
          }
        }
    },
    ON_HOTSPOT_ICON_MODIFY: (state, hotspot) => {
        modifyHostpotIconThumb(state.krpano, hotspot)
    },
    ON_HOTSPOT_NAME_MODIFY: (state, hotspot) => {
        modifySceneHotspotName(state.krpano, hotspot)
    },
    LOOK_TO_HOTSPOT: (state, hotspotId) => {
        lookToHotspot(state.krpano, hotspotId)
    },
    ON_HOTSPOT_CLICK_FOCUS: (state, hotspotId) => {
        state.currentHotspotId=hotspotId
    },
    ON_HOTSPOT_REMOVE: (state, hotspot) => {
        
        let scene = state.scenes.find(o => o.id == hotspot.sceneId)
        if (scene) {
          const index = scene.hotspots.findIndex(o=>o.id==hotspot.id)
          if(index>=0){
            scene.hotspots.splice(index,1)
          }
        }
        removeHotspot(state.krpano, hotspot.id)
    },
    ON_PROJECT_NAME_MODIFY: (state, name) => {
        state.name=name
    },
    ON_PROJECT_COVER_MODIFY: (state, cover) => {
        state.cover=cover
    },
    ON_PROJECT_DESC_MODIFY: (state, desc) => {
        state.description=desc
    },
    
}