import Vue from 'vue'
import Component from './func-notify'
import { NOTIFY_ZINDEX } from 'common/js/common.js'
const NotifyConstructor = Vue.extend(Component)
const instances = []
let seed = 0

const removeInstance = (instance) => {
  if (!instance) {
    return
  }
  const index = instances.findIndex(o => o.id === instance.id)
  if (index >= 0) {
    instances.splice(index, 1)
  }
}
const notify = (options) => {
  const instance = new NotifyConstructor({
    propsData: options
  })
  instance.id = `notify_${seed++}`
  instance.vm = instance.$mount()
  document.body.appendChild(instance.vm.$el)
  instance.zindex = NOTIFY_ZINDEX + seed
  instance.visiable = true
  instances.push(instance)
  instance.vm.$on('close', () => {
    instance.vm.visiable = false
  })
  instance.vm.$on('closed', () => {
    removeInstance(instance)
    document.body.removeChild(instance.vm.$el)
    instance.vm.$destroy()
  })
  return instance.vm
}
export default notify
