
const TOKEN = 'pano_center_token'

export function getToken() {
  // return "11eyJhbGciOiJIUzUxMiJ9.eyJ1aWQiOiJiZDcxNTYwMDIwNDI0MTcxNTIwMCIsInN1YiI6ImJkNzE1NjAwMjA0MjQxNzE1MjAwIiwicm9sZSI6ImFwcCIsIm5hbWUiOiJ0ZXN0IiwiZXhwIjoxNjg4NTQ5NTM3LCJpYXQiOjE2ODU5NTc1Mzd9.MApWhguPUn8mcQe6jnLZqkaXPAmxQTgMVhvuZsRuYWE94pLB8dFfi0I1bPdjNNbjzpgbZm7FoVQCamBsuc5m7w";
  return localStorage.getItem(TOKEN)
}

export function setToken(token) {
  return localStorage.setItem(TOKEN, token)
}

export function removeToken() {
  return localStorage.removeItem(TOKEN)
}
