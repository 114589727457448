import Vue from 'vue'
import SelectItem from './func-select-item'
const instances = []
let seed = 0

const removeInstance = (instance) => {
  if (!instance) {
    return
  }
  const index = instances.findIndex(o => o.id === instance.id)
  if (index >= 0) {
    instances.splice(index, 1)
  }
}

// 是否是祖先元素
const isClosest = (target, element) => {
  if (target === document) {
    return false
  } else if (target === element) {
    return true
  } else {
    return isClosest(target.parentNode, element)
  }
}
const selectItem = (options) => {
  if (!options.e) {
    return;
  }
  const {x,bottom,width} = options.e.target.getBoundingClientRect()
  const clientX=x
  const clientY=bottom+5
  const triggerEl = options.e.target;
  if (triggerEl && instances.some(o => o.triggerEl == triggerEl))
    return;
  const templateSelectItem = options.template
  let SelectItemInstance = Object.assign({}, SelectItem)
  SelectItemInstance.components = {
    templateSelectItem
  }
  const SelectItemConstructor = Vue.extend(SelectItemInstance)
  const instance = new SelectItemConstructor({
    propsData: options
  })
  instance.triggerEl = triggerEl;
  instance.id = `selectItem_${seed++}`
  instance.vm = instance.$mount()
  document.body.appendChild(instance.vm.$el)
  instance.zindex = 100 + seed
  instance.left = `${clientX}px`
  instance.top = `${clientY}px`
  instance.width = `${width}px`
  instance.visiable = true


  const hanhandleSelectItemVisible = (e) => {
    e.stopPropagation()
    if (isClosest(e.target, instance.vm.$el)) {
      return;
    }
    instance.vm.$emit('close');
  }
  const handleSelectItemInvisible = (e) => {
    if (isClosest(e.target, triggerEl)) {
      return;
    }
    instance.vm.$emit('close');
  }
  setTimeout(() => {
    instance.vm.$el.addEventListener('click', hanhandleSelectItemVisible);
    document.addEventListener('click', handleSelectItemInvisible);
  }, 100);
  instances.push(instance)

  instance.vm.$on('close', () => {
    instance.vm.visiable = false
  })
  instance.vm.$on('closed', (o) => {
    instance.vm.visiable = false
    removeInstance(instance)
    instance.vm.$el.removeEventListener('click', hanhandleSelectItemVisible);
    document.removeEventListener('click', handleSelectItemInvisible);
    document.body.removeChild(instance.vm.$el)
    instance.vm.$destroy()
    if (options.close) {
      options.close(o)
    }
  })
  return instance.vm
}
export default selectItem
