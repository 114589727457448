import Vue from 'vue'
import VueRouter from 'vue-router'

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    redirect: '/webapp'
  },
  {
    path: '/login',
    component: () => import('../views/login.vue'),
  },
  {
    path: '/custom',
    name: 'custom',
    component: () => import('../views/custom/Custom.vue'),
    children: [
      {
        path: '',
        redirect: 'home'
      },
      {
        path: 'home',
        name: 'home',
        component: () => import('../views/custom/home')
      }
    ]
  },
  {
    path: '/my',
    name: 'my',
    component: () => import('../views/my/My.vue'),
    children: [
      {
        path: '',
        redirect: 'manage'
      },
      {
        path: 'manage',
        name: 'manage',
        component: () => import('../views/my/manage')
      },
      {
        path: 'publish',
        name: 'publish',
        component: () => import('../views/my/publish')
      },
      {
        path: 'edit-pano',
        name: 'edit-pano',
        component: () => import('../views/my/edit-pano')
      }
    ]
  },
  {
    path: '/edit',
    name: 'edit',
    component: () => import('../views/edit/Edit.vue')
  },
  {
    path: '/admin',
    name: 'admin',
    component: () => import('../views/admin/Admin.vue'),
    children: [
      {
        path: '',
        redirect: 'home'
      },
      {
        path: 'home',
        name: 'home',
        component: () => import('../views/admin/home')
      }
    ]
  },
  {
    path: '/webapp',
    name: 'webapp',
    component: () => import('../views/webapp/index.vue'),
    children: [
      {
        path: '',
        redirect: '/webapp/project'
      },
      {
        path: 'edit/:id',
        name: 'edit',
        component: () => import('../views/webapp/edit')
      },
      {
        path: 'pano/:id',
        name: 'pano',
        component: () => import('../views/webapp/pano')
      },
      {
        path: 'pano2/:id',
        name: 'pano2',
        component: () => import('../views/webapp/pano2')
      },
      {
        path: 'panoView/:id',
        name: 'panoView',
        component: () => import('../views/webapp/panoView'),
        props: route => {
          return route.query;
        }
      }, {
        path: 'panoView2/:id',
        name: 'panoView2',
        component: () => import('../views/webapp/panoView2'),
        props: route => {
          return route.query;
        }
      },
      {
        path: 'project',
        name: 'project',
        component: () => import('../views/webapp/project')
      }
    ]
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes
})

export default router
