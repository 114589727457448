import Vue from 'vue'
import collect from '@/common/js/collect'
export default {
    SELECT_IMG: (state, imgEntry) => {
        state.imgs.push(imgEntry)
    },
    UPLOAD_IMG: (state, newImg) => {
        const index = state.imgs.findIndex(o => o.id == newImg.id)
        Vue.set(state.imgs, index, {
            ...newImg
        })
    },
    NOTIFY_CUTED: (state, id) => {

    },
    REMOVE_IMG: (state, id) => {
        collect.remove(state.imgs, o=>o.id==id)
        // const index = state.imgs.findIndex(o => o.id == id)
        // state.imgs.splice(index, 1)
    },
    SET_TITLE: (state, title) => {
        state.title = title
    },
    PUBLISH_SUCCESS: (state) => {
        state.title = ''
        state.imgs = []
    },
    CUT_COMPLETE: (state, ids) => {
        for (let index = 0; index < ids.length; index++) {
            const id = ids[index];
            const imgIndex = state.imgs.findIndex(o => o.serverId == id)
            if(imgIndex>-1){

                let originalImg = state.imgs[imgIndex]
                originalImg.status = 2
    
                Vue.set(state.imgs, imgIndex, {
                    ...originalImg
                })
            }

        }
    }
}