import Popup from './popup.vue'
export default {
  extends: Popup,
  computed: {
    style () {
      return {
        zIndex: this.zindex,
        left: this.left,
        bottom: this.bottom
      }
    }
  },
  data () {
    return {
      zindex: 0,
      left:'0px',
      bottom:'0px',
      key:null
    }
  },
  components: {
    templatePopup: null
  }
}
