import Vue from 'vue'
import Modal from './modal.vue'
// import { ALERT_ZINDEX } from 'common/js/common.js'

const instances = []
let seed = 0
const removeInstance = (instance) => {
  if (!instance) {
    return
  }
  const index = instances.findIndex(o => o.id === instance.id)
  if (index >= 0) {
    instances.splice(index, 1)
  }
}
const modal = (options) => {
  const templateModal = options.template
  let ModalInstance = Object.assign({}, Modal)
  ModalInstance.components = {
    templateModal
  }
  const ModalConstructor = Vue.extend(ModalInstance)
  const instance = new ModalConstructor({
    propsData: options
  })
  instance.id = `modal_${seed++}`
  instance.params = options.params || {}
  instance.vm = instance.$mount()
  document.body.appendChild(instance.vm.$el)

  // instance.zindex = ALERT_ZINDEX + seed
  instance.visiable = true
  instances.push(instance)
  instance.vm.$on('closed', (o) => {
    removeInstance(instance)
    document.body.removeChild(instance.vm.$el)
    instance.vm.$destroy()
    if (options.close) {
      options.close(o)
    }
  })
  return instance.vm
}

export default modal

// const modalStack = []
// let ModalConstructor = () => { }

// const initModal = (Main) => {
//   ModalConstructor = Vue.extend(Main)
// }

// var getAnInstance = () => {
//   if (modalStack.length > 0) {
//     var instance = modalStack[0]
//     modalStack.splice(0, 1)
//     return instance
//   }
//   return new ModalConstructor({
//     el: document.createElement('div')
//   })
// }
// let isExistModal = false
// const BoqiiModal = (options = {}) => {
//   if (isExistModal) return
//   var templateModal = options.template
//   var instance = {}
//   // var Modal = Object.assign({}, Modal)
//   isExistModal = true
//   Modal.components = {
//     templateModal
//   }

//   console.log(Modal)
//   initModal(Modal)
//   instance = getAnInstance()
//   instance.closed = false

//   document.body.appendChild(instance.$el)
//   // Vue.nextTick(function () {
//   //   instance.enterAnimate = enterAnimate || 'bounceInDown'
//   //   instance.leaveAnimate = leaveAnimate || 'bounceOutUp'
//   //   instance.propsParams = props
//   //   instance.modalVisible = true
//   //   instance.visible = true
//   //   instance.duration = duration
//   //   instance.backdropClickable = backdropClickable
//   //   if (!backScroll) {
//   //     document.body.style.overflow = 'hidden'
//   //     scrollTop = document.scrollingElement.scrollTop
//   //     document.body.style.top = -scrollTop + 'px'
//   //     document.body.style.width = '100vw'
//   //     document.body.style.position = 'fixed'
//   //   }
//   // })
//   return instance
// }

// export default BoqiiModal
