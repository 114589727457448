import Container from './container'
import Hotspot from './hotspot'
export default {
    title:'测试的全景',
    description:'',
    tags:[],
    slide:null,//{type:vue组件,reload:是否需要重新加载,params:{xxx,xx,xx,}}
    leftNavIndex:0,
    leftNavs:Container.leftNavs,
    krpano:null,
    config:{
        scenes:[
            {
                sceneId:'a1',
                sceneName:'场景1',
                hotspots:[
                ]
            }
        ]
    },
    currentSceneId:'a1',
    currentSceneHotspotId:null,
    hotspotConfig:{...Hotspot},
}