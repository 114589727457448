<template>
  <div class="hotspot-size-container">
    <div class="hotspot-size-display">
      <div>图标大小</div>
      <div>{{size}}</div>
    </div>
    <div>
    <HotspotSliderSize @rightHandle="onHotspotSizeChange" :initValue="initSize"></HotspotSliderSize></div>
    <div class="hotspot-size-limit-display">
      <div>0</div>
      <div>1</div>
    </div>
  </div>
</template>
<script>
import HotspotSliderSize from './components/hotspot-slider-size'
import Util from "@/common/js/util"
export default {
  props: {
    initSize:{
      default:1
    }
  },
  mounted () {
    this.size = this.initSize
  },
  data () {
    return {
      size: 1
    }
  },
  methods: {
    onHotspotSizeChange (size) {
      this.size=Util.toDecimal1(size)
      this.$emit('onSizeChange', this.size)
    }
  },
  components: {
    HotspotSliderSize
  }
}
</script>
<style lang="scss">
.hotspot-size-container {
  padding: 15px;
  .hotspot-size-display{
    display: flex;
    justify-content: space-between;
    margin-bottom: 10px;
  }
  .hotspot-size-limit-display{
    display: flex;
    justify-content: space-between;
    margin-top: 10px;
  }
}
</style>