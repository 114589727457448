import Alert from './alert.vue'

export default {
  extends: Alert,
  computed: {
    style () {
      return {
        zIndex: this.zindex
      }
    }
  },
  data () {
    return {
      zindex: 0
    }
  }
}
