<template>
  <div
    class="pano-select-item-wrap-container"
    :style="{'width':width}"
  >
    <div
      v-for="item in items"
      :key="item.value"
      class="pano-select-item-wrap-item"
      :class="{'pano-select-item-wrap-item-selected':item.value==currentItem}"
      @click="select(item.value)"
    >{{item.name}}</div>
  </div>
</template>
<script>
export default {
  props: {
    width: {
      type: String,
      default: '0px'
    },
    items: {
      type: Array,
      default: () => []
    },
    currentItem:{
      type:String,
      default:''
    }
  },
  methods: {
    select (value) {
      const item=this.items.find(o=>o.value==value)
      this.$emit('close', {...item});
    }
  },
};
</script>
<style lang="scss">
.pano-select-item-wrap-container {
  background: #333333;
  color: #ffffff;
  border-radius: 5px;
  border: 1px solid#383838;
  box-shadow: -4px 3px 7px 3px rgba($color: #000000, $alpha: 0.6);

  .pano-select-item-wrap-item {
    height: 30px;
    display: flex;
    align-items: center;
    cursor: pointer;
    padding: 0 20px;
  }
  .pano-select-item-wrap-item-selected{
    background: #555555;
  }
  .pano-select-item-wrap-item:hover {
    background: #666666;
  }
}
</style>