import Vue from 'vue'
import Popup from './func-popup'
const instances = []
let seed = 0

const removeInstance = (instance) => {
  if (!instance) {
    return
  }
  const index = instances.findIndex(o => o.id === instance.id)
  if (index >= 0) {
    instances.splice(index, 1)
  }
}

// 是否是祖先元素
const isClosest = (target, element) => {
  if (target === document) {
    return false
  } else if (target === element) {
    return true
  } else {
    return isClosest(target.parentNode, element)
  }
}
const popup = (options) => {
  if (!options.e) {
    return;
  }

  const {x,y,width} = options.e.target.getBoundingClientRect()
  const clientX=x+width
  const clientY=y
  const triggerEl = options.e.target;
  if (triggerEl && instances.some(o => o.triggerEl == triggerEl))
    return;
  const templatePopup = options.template
  let PopupInstance = Object.assign({}, Popup)
  PopupInstance.components = {
    templatePopup
  }
  const PopupConstructor = Vue.extend(PopupInstance)
  const instance = new PopupConstructor({
    propsData: options
  })
  instance.triggerEl = triggerEl;
  instance.id = `popup_${seed++}`
  instance.vm = instance.$mount()
  document.body.appendChild(instance.vm.$el)
  instance.zindex = 100 + seed
  instance.left = `${clientX}px`
  instance.bottom = `${window.innerHeight - clientY}px`
  instance.visiable = true


  const handlePopupVisible = (e) => {
    e.stopPropagation()
    if (isClosest(e.target, instance.vm.$el)) {
      return;
    }
    instance.vm.$emit('close');
  }
  const handlePopupInvisible = (e) => {
    if (isClosest(e.target, triggerEl)) {
      return;
    }
    instance.vm.$emit('close');
  }
  setTimeout(() => {
    instance.vm.$el.addEventListener('click', handlePopupVisible);
    document.addEventListener('click', handlePopupInvisible);
  }, 100);
  instances.push(instance)

  instance.vm.$on('close', () => {
    instance.vm.visiable = false
  })
  instance.vm.$on('closed', (o) => {
    instance.vm.visiable = false
    removeInstance(instance)
    instance.vm.$el.removeEventListener('click', handlePopupVisible);
    document.removeEventListener('click', handlePopupInvisible);
    document.body.removeChild(instance.vm.$el)
    instance.vm.$destroy()
    if (options.close) {
      options.close(o)
    }
  })
  return instance.vm
}
export default popup
