import { post } from 'common/js/http'

export function upload (formData) {
  return post('/app/material/upload', formData)
}
export function cutComplete(serverIds) {
  return post('/app/material/cutComplete', serverIds)
}
export function publish (porjectInfo) {
  return post('/app/project/publish', porjectInfo)
}

