<template>
  <div class="fov-set-container">
    <div class="fov-set-container">{{ title }}</div>
    <slider-horizontal-vertical-fov
      ref="fov"
      @leftHandle="fovLeft"
      @rightHandle="fovRight"
      :currentMin="minValue"
      :currentMax="maxValue"
      :min="min"
      :max="max"
    ></slider-horizontal-vertical-fov>
    <div class="fov-set-input-container">
      <div class="fov-set-input-value">
        <pano-slider-input
          v-model="currentMin"
          @blur="fovLeftBlur"
        ></pano-slider-input>
        <div class="fov-set-label">最低</div>
      </div>
      <div class="fov-set-input-value">
        <pano-slider-input
          v-model="currentMax"
          @blur="fovRightBlur"
        ></pano-slider-input>
        <div class="fov-set-label">最高</div>
      </div>
    </div>
  </div>
</template>
<script>
import PanoSliderInput from "../pano-slider-input";
import SliderHorizontalVerticalFov from "../slider-horizontal-vertical-fov";
import Util from "@/common/js/util";
export default {
  props: {
    title: {
      type: String,
      default: "未设置",
    },
    min: {
      type: Number,
      default: 0,
    },
    max: {
      type: Number,
      default: 0,
    },
    minValue: {
      type: Number,
      default: 0,
    },
    maxValue: {
      type: Number,
      default: 0,
    },
  },
  data() {
    return {
      currentMin:0,
      currentMax:0
    };
  },
  mounted(){
this.currentMin=this.minValue
this.currentMax=this.maxValue
  },
  methods: {
    fovLeft(v) {
      this.currentMin = Util.toDecimal1(v);
    },
    fovRight(v) {
      this.currentMax = Util.toDecimal1(v);
    },
    fovLeftBlur(v) {
      if (v < this.min) {
        v = this.min;
      } else if (v > this.currentMax) {
        v = this.currentMax;
      }
      this.currentMin = v;
      this.$refs.fov.setFov(this.currentMin, this.currentMax);
    },
    fovRightBlur(v) {
      if (v < this.currentMin) {
        v = this.currentMin;
      } else if (v > this.max) {
        v = this.max;
      }
      this.currentMax = v;
      this.$refs.fov.setFov(this.currentMin, this.currentMax);
    },
  },
  components: {
    SliderHorizontalVerticalFov,
    PanoSliderInput,
  },
};
</script>
<style lang="scss">
.fov-set-container {
  .fov-set-container {
    margin-bottom: 20px;
    color: #ffffff;
  }
  .fov-set-input-container {
    display: flex;
    justify-content: space-between;
    margin-top: 20px;
    .fov-set-input-value {
      width: 20%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .fov-set-label {
        margin-top: 5px;
        font-size: $font-size-small;
        color: #999999;
      }
    }
  }
}
</style>