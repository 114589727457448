import SelectItem from '../pano-select-item/index.vue'
export default {
  extends: SelectItem,
  computed: {
    style () {
      return {
        zIndex: this.zindex,
        left: this.left,
        top: this.top,
        width:this.width
      }
    }
  },
  data () {
    return {
      zindex: 0,
      left:'0px',
      top:'0px',
      width:'0px',
      key:null
    }
  },
  components: {
    templateSelectItem: null
  }
}
