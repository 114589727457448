import state from "./state"
export default {
    addTag: ({ commit }, param) => {
        commit("ADD_TAG", param)
    },
    //打开侧边栏
    openSlide: ({ commit }, slide) => {
        console.log('openSlide')
        console.log((slide || {}))
        console.log((slide || {}).reload)
        //是否需要重载侧边栏
        if ((slide || {}).reload) {
            //先关闭再打开
            commit('CLOSE_SLIDE')
            setTimeout(() => {
                commit('OPEN_SLIDE', slide)
            }, 1000 / 30);
        } else {
            commit('OPEN_SLIDE', slide)
        }
    },
    closeSlide:({commit})=>{
        console.log('closeSlide')
        commit('CLOSE_SLIDE')
    },
    leftNavClick:({dispatch,commit},index)=>{
        if(state.slide!=null){
            dispatch('closeSlide')
            .then(()=>{
                commit('LEFT_NAV_CLICK',index)
            })
        }else{
            commit('LEFT_NAV_CLICK',index)
        }
    },
    initEdit:({commit},kp)=>{
        commit('INIT_EDIT',kp)
    },
    addHotspot:({commit},hotspot)=>{
        console.log('添加热点')
        console.log(hotspot)
        commit('ADD_HOTSPOT',hotspot)
    },
    setHotspot:({commit},hsId)=>{
        console.log('设置当前热点')
        commit('SET_CURRENT_HOTSPOT_ID',hsId)
    },
    clearHotspot:({commit})=>{
        console.log('清空当前热点')
        commit('SET_CURRENT_HOTSPOT_ID',null)
    },
    modifyHotspotIcon:({commit},hotspot)=>{
        commit('MODIFY_HOTSPOT_ICON',hotspot)
    },
    modifyHotspotSize:({commit},hotspot)=>{
        commit('MODIFY_HOTSPOT_SIZE',hotspot)
    },
    modifyHotspotTitle:({commit},hotspot)=>{
        commit('MODIFY_HOTSPOT_TITLE',hotspot)
    },
    deleteHotspot:({commit},hotspot)=>{
        console.log(hotspot)
        commit('DELETE_HOTSPOT',hotspot)
    }
}