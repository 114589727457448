export default {
    title:'',
    imgs:[], 
}

//imgs:[],  {
    //     id:id,
    //     serverId:null,
    //     name: Util.getFileNameWithoutExtension(f.name),
    //     fullName: f.name,
    //     watermark: true,
    //     base64: base64Data,
    //     status:0
    //   }