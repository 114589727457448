<template>
  <div class="embed-prop-container">
    <prop-scroll> embed </prop-scroll>
  </div>
</template>
<script>
import PropScroll from "../prop-scroll";
export default {
  components: {
    PropScroll,
  },
};
</script>
<style lang="scss">
.embed-prop-container {
  display: flex;
  flex-direction: column;
  height: 100%;
}
</style>