<template>
  <div class="pano-hotspot-edit-view-container" v-if="inited">
    <HotspotEditTitle name="添加热点"></HotspotEditTitle>
    <HotspotEditScroll>
      <HotspotEditSubTitle name="选择图标"></HotspotEditSubTitle>
      <div class="hotspot-select-container">
        <pano-select
          :items="iconItems"
          v-model="hotspot.iconType"
        ></pano-select>
      </div>
      <div v-if="hotspot.iconType=='1'">
        <HotspotIconType1
          :iconId="defaultIconId"
          @select="onHotspotIconSelect"
        ></HotspotIconType1>
      </div>
      <HotspotSize
        @onSizeChange="onSizeChange"
        :initSize="hotspotSize"
      ></HotspotSize>

      <HotspotEditSubTitle name="选择热点类型"></HotspotEditSubTitle>
      <div class="hotspot-type-select-container">
        <pano-select
          :items="hotspotTypes"
          v-model="hotspot.hotspotType"
        ></pano-select>
      </div>
      <div class="hotspot-type-selected-container">
        <div class="hotspot-type-title-container">
          <div class="hotspot-type-title">标题 <span>{{hotspotTitle.length}}/50</span></div>
          <div>显示标题</div>
        </div>
        <PanoEditInput
          placeholder="请输入标题"
          v-model="hotspot.title"
        ></PanoEditInput>
        <HotspotFontSize v-model="hotspot.fontSize"></HotspotFontSize>
        <div class="hotspot-scene-anim-container">
          <div class="hotspot-scene-anim-title">场景切换效果</div>
          <pano-select
            :items="hotspotSceneAnims"
            v-model="hotspot.hotspotSceneAnim"
          ></pano-select>
        </div>
      </div>
    </HotspotEditScroll>
    <div class="pano-hotspot-del-container">
      <pano-button
        name="删除"
        type="del"
        @click="delHotspot"
      ></pano-button>
    </div>
  </div>
</template>
<script>
import PanoEditInput from "../../../../../base-components/pano-edit-input";
import HotspotEditTitle from "../hotspot-edit-title";
import HotspotEditScroll from "../hotspot-edit-scroll";
import HotspotEditSubTitle from "../hotspot-edit-sub-title";
import HotspotIconType1 from "./components/hotspot-icon-type-1";
import HotspotSize from "./components/hotspot-size";
import HotspotFontSize from "./components/hotspot-font-size-select";
import { mapGetters, mapState, mapActions } from "vuex";
import Util from "@/common/js/util";
export default {
  watch: {
    hotspotTitle() {
      this.modifyHotspotTitle(this.hotspot);
    },
    hotspotFontSize() {
      this.modifyHotspotTitle(this.hotspot);
    },
  },
  computed: {
    ...mapGetters("editModule", ["currentScene", "currentHotspot"]),
    ...mapState("editModule", ["currentSceneId", "hotspotConfig", "slide"]),
    defaultIconId() {
      return this.hotspot.iconId || "";
    },
    hotspotTitle() {
      return this.hotspot.title || "";
    },
    hotspotFontSize() {
      return this.hotspot.fontSize;
    },
    hotspotSize() {
      return this.hotspot.size / 50;
    }
  },
  mounted() {
    if (this.slide.params.hsId) {
      this.setHotspot(this.slide.params.hsId);
      if (this.currentHotspot) {
        this.hotspot = this.currentHotspot
        console.log(this.hotspot)
      }
    } else {
      let hot = {
        hsId: `hotspot_${Util.newGuid(32, 16)}`,
        sceneId: this.currentSceneId,
        iconType: "1",
        iconId: this.hotspotConfig.sysIcons[0].id,
        iconUrl: this.hotspotConfig.sysIcons[0].iconUrl,
        hotspotType: "1",
        title: "",
        size: 50,
        fontSize: 12,
        hotspotSceneAnim: "simple crossblending",
      };
      // if (this.currentHotspot) {
      //   hot = { ...this.currentHotspot }
      // }
      console.log(hot.hsId);
      this.hotspot = { ...hot };
      this.addHotspot(this.hotspot);
      this.setHotspot(hot.hsId);
    }
    this.inited= true
  },
  data() {
    return {
      inited: false,
      hotspot: {},
      iconItems: [
        {
          name: "系统图标",
          value: "1",
        },
        {
          name: "自定义图标",
          value: "2",
        },
        {
          name: "多边形图标",
          value: "3",
        },
      ],
      hotspotTypes: [
        {
          name: "请选择一种热点类型",
          value: "",
        },
        {
          name: "全景切换",
          value: "1",
        },
      ],
      hotspotSceneAnims: [
        {
          name: "无过渡效果",
          value: "no blending",
        },
        {
          name: "淡入淡出",
          value: "simple crossblending",
        },
        {
          name: "缩放过渡",
          value: "zoom blend",
        },
        {
          name: "黑场过渡",
          value: "black-out",
        },
        {
          name: "白场过渡",
          value: "white-flash",
        },
        {
          name: "从右至左",
          value: "right-to-left",
        },
        {
          name: "从上至下",
          value: "top-to-bottom",
        },
        {
          name: "对角线",
          value: "diagonal",
        },
        {
          name: "圆形展开",
          value: "circle open",
        },
        {
          name: "垂直展开",
          value: "vertical open",
        },
        {
          name: "水平展开",
          value: "horizontal open",
        },
        {
          name: "椭圆缩放",
          value: "elliptic + zoom",
        },
      ],
    };
  },
  methods: {
    ...mapActions("editModule", [
      "addHotspot",
      "modifyHotspotIcon",
      "setHotspot",
      "modifyHotspotSize",
      "modifyHotspotTitle",
      "deleteHotspot",
      "closeSlide"
    ]),
    onHotspotIconSelect(icon) {
      this.hotspot.iconId = icon.id;
      this.hotspot.iconUrl = icon.iconUrl;
      this.modifyHotspotIcon(this.hotspot);
    },
    onSizeChange(size) {
      this.hotspot.size = 50 * size
      this.modifyHotspotSize(this.hotspot);
    },
    async delHotspot() {
      await this.deleteHotspot(this.hotspot)
      await this.closeSlide()
    }
  },
  components: {
    HotspotEditTitle,
    HotspotEditScroll,
    HotspotEditSubTitle,
    HotspotIconType1,
    HotspotSize,
    PanoEditInput,
    HotspotFontSize
  },
};
</script>
<style lang="scss" scoped>
.pano-hotspot-edit-view-container {
  display: flex;
  flex-direction: column;
  height: 100%;
  .pano-hotspot-edit-view-container:first-child {
    border-top: none;
  }
  .hotspot-select-container,
  .hotspot-type-select-container {
    padding: 15px;
  }
  .hotspot-type-selected-container {
    padding: 0 15px;
    .hotspot-type-title-container {
      color: #ffffff;
      display: flex;
      justify-content: space-between;
      line-height: 40px;
      font-size: $font-size-small-x;
      .hotspot-type-title {
        span {
          color: #666666;
        }
      }
    }
    .hotspot-scene-anim-container {
      margin-top: 10px;
      .hotspot-scene-anim-title {
        color: #ffffff;
        height: 40px;
        line-height: 40px;
      }
    }
  }
  .pano-hotspot-del-container {
    padding: 20px;
  }
}
</style>