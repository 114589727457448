<template>
  <div class="hotspot-icon-type-1-container">
    <div
      class="hotspot-icon-type-item-container"
      :class="{ 'hotspot-icon-type-item-active': item.id == current}"
      v-for="item in hotspotConfig.sysIcons"
      :key="item.id"
      @click="doAddHotspot(item)"
    >
      <img class="hotspot-icon-type-item-img" :src="item.iconUrl" alt="" />
    </div>
  </div>
</template>
<script>
import { mapState } from 'vuex';
export default {
  props: {
    iconId:{
      type:String,
      default:''
    }
  },
  mounted() {
    this.current=this.iconId;
  },
  computed: {
    ...mapState('editModule', ['hotspotConfig'])
  },
  data() {
    return {
      current: '',
    };
  },
  methods: {
    doAddHotspot(icon) {
      this.current=icon.id
      this.$emit("select", icon);
    },
  },
};
</script>
<style lang="scss">
.hotspot-icon-type-1-container {
  display: flex;
  flex-wrap: wrap;
  padding: 0 15px;
  height: 150px;
  overflow-y: scroll;
  .hotspot-icon-type-item-container {
    cursor: pointer;
    width: 20%;
    max-width: 20%;
    margin-bottom: 10px;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 2px solid transparent;
    border-radius: 4px;
    box-sizing: border-box;
    padding: 2px 0;
    .hotspot-icon-type-item-img {
      width: 80%;
    }
  }
  .hotspot-icon-type-item-active {
    border: 2px solid #ffa807;
  }
}
.hotspot-icon-type-1-container::-webkit-scrollbar {
  display: none;
}
</style>