<template>
  <div class="pano-switch-container">
    <div class="pano-switch-wrapper" 
        @click="switchChange()"
        >
      <div class="pano-switch-label">{{name}}</div>
      <div class="pano-switch-value" >
        <div
          class="pano-switch-dot"
          :class="{'pano-switch-dot-false':!switchValue}"
        >
        <div class="pano-switch-inner-dot"></div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
export default {
  props: {
    name: {
      type: String,
      default: ''
    },
    value: {
      type: Boolean,
      default: true
    },
  },
  mounted () {
    this.switchValue = this.value
  },
  data () {
    return {
      switchValue: false
    }
  },
  methods: {
    switchChange () {
      this.switchValue = !this.switchValue
    }
  }
}
</script>
<style lang="scss">
.pano-switch-container {
  display: inline-block;
  height: 30px;
  .pano-switch-wrapper {
    display: flex;
    align-items: center;
      cursor: pointer;
    .pano-switch-label {
      margin-right: 5px;
      font-size: $font-size-small-x;
    }
    .pano-switch-value {
      width: 36px;
      border-radius: 12px;
      border: 1px solid #4b4b4b;
      height: 16px;
      position: relative;
      .pano-switch-dot {
        width: 20px;
        height: 20px;
        border-radius: 10px;
        box-sizing: border-box;
        border: 1px solid #4b4b4b;
        background: #ffffff;
        position: absolute;
        top: -2px;
        right: -1px;
        transition: all 0.3s ease;
        display: flex;
        align-items: center;
        justify-content: center;
        .pano-switch-inner-dot{
          background: $color-theme;
          width: 10px;
          height: 10px;
          border-radius: 5px;
        }
      }
      .pano-switch-dot-false {
        right: 17px;
        background: #4b4b4b;
        .pano-switch-inner-dot{
          background: none;
        }
      }
    }
  }
}
</style>