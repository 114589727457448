<template>

  <transition
    name="popup"
    @after-leave="afterLeave"
  >
  <div class="popup-container" v-if="visiable"  :style="style">
    <div class="popup-wrapper">
     <templatePopup  @close="close"
        ></templatePopup>
    </div>
  </div>
  </transition>
</template>

<script>
export default {
  name: "Popup",
  data() {
    return {
      visiable: false,
      output:null
    };
  },
  methods: {
    close (p) {
      this.output = p
      this.visiable = false
    },
    afterLeave () {
      this.$emit('closed', this.output)
    }
  }
};
</script>

<style lang="scss" scoped>
.popup-container {
  position: fixed;
  display: flex;
  justify-content: center;
  .popup-wrapper {
    flex: 1;
  }
}
</style>
