<template>
  <transition
    name="notify"
    @after-leave="afterLeave"
  >
    <div
      class="notify-container"
      v-show="visiable"
      :style="style"
      @touchstart.stop.prevent="onShortcutTouchStart"
      @touchmove.stop.prevent="onShortcutTouchMove"
      @touchend.stop.prevent="onShortcutTouchEnd"
    >
      <div class="notify-wrapper">
        <div class="title-container">{{title}}</div>
        <div class="content-container">{{content}}</div>
      </div>
    </div>
  </transition>
</template>

<script>
export default {
  name: 'Notify',
  props: {
    title: {
      type: String,
      required: true
    },
    content: {
      type: String,
      required: true
    }
  },
  computed: {
    style () {
      return {}
    }
  },
  data () {
    return {
      visiable: false,
      timeOut: 3000
    }
  },
  mounted () {
    this.touch = {}
    this._createTimer()
  },
  methods: {
    afterLeave () {
      this.$emit('closed')
    },
    afterDestroy () {
      this._clearTimer()
    },
    onShortcutTouchStart (e) {
      this._clearTimer()
      let startTouch = e.touches[0]
      this.touch.y1 = startTouch.clientY
    },
    onShortcutTouchMove (e) {
      let endTouch = e.touches[0]
      this.touch.y2 = endTouch.clientY
    },
    onShortcutTouchEnd (e) {
      if ((this.touch.y1 - this.touch.y2) > 10) {
        this._close()
      } else {
        this._createTimer()
      }
    },
    _createTimer () {
      if (this.timeOut) {
        this.timer = setTimeout(() => {
          this._close()
        }, this.timeOut)
      }
    },
    _clearTimer () {
      if (this.timer) {
        clearTimeout(this.timer)
      }
    },
    _close () {
      this.visiable = false
      this.$emit('close')
    }
  }
}
</script>

<style lang="scss" scoped>
.notify-container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  padding: 0.2rem;
  .notify-wrapper {
    flex: 1;
    border-radius: 0.28rem;
    overflow: hidden;
    background: rgba(255, 255, 255, 0.95);
    box-shadow: 0 0.02rem 0.1rem rgba(0, 0, 0, 0.3);
    .title-container {
      font-size: 0.3rem;
      font-weight: 500;
      padding: 0.2rem;
    }
    .content-container {
      font-size: 0.24rem;
      padding: 0.2rem;
    }
  }
}
</style>
