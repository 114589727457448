<template >
  <div
    class="pano-select-container ellipsis"
    :class="{
      'pano-select-small': size == 'small',
      'pano-select-medium': size == 'medium',
      'pano-select-large': size == 'large',
      'pano-select-mode-dark': mode == 'dark',
    }"
    @click="click($event)"
  >
    {{ currentValue.name }}
    <i class="iconfont">&#xe653;</i>
  </div>
</template>
<script>
import SelectItemWrap from "./components/pano-select-item-wrap";
export default {
  name: "PanoSelect",
  model: {
    prop: "value",
    event: "select",
  },
  props: {
    value: {
      type: String,
      default: "",
    },
    size: {
      type: String,
      default: "small",
    },
    mode: {
      type: String,
      default: "dark",
    },
    items: {
      type: Array,
      default: () => [],
    },
    selectTip: {
      type: Boolean,
      default: false,
    },
  },
  // mounted() {
  //   if (this.displayItems.length > 0) {
  //     if (this.value != "") {
  //       let v = this.displayItems.find((o) => o.value == this.value);
  //       if (v) {
  //         this.current = { ...v };
  //       }
  //     } else {
  //       this.current = { ...this.displayItems[0] };
  //     }
  //   }
  // },
  data() {
    return {};
  },
  computed: {
    currentValue() {
      if (this.displayItems.length) {
        const showItem=this.displayItems.find((o) => o.value == this.value);
        if(showItem){
          return showItem
        }
        return this.displayItems[0];
      }
      return {};
    },
    displayItems() {
      if (this.selectTip)
        return [{ name: "-- 请选择 --", value: "" }, ...this.items];
      else return [...this.items];
    },
  },
  methods: {
    click(e) {
      this.$selectItem({
        e: e,
        template: SelectItemWrap,
        currentItem: this.currentValue.value,
        items: this.displayItems,
        close: (o) => {
          if (o) {
            this.$emit("select", o.value);
          }
        },
      });
    },
  },
};
</script>
<style lang="scss">
.pano-select-container {
  background: $color-theme;
  transition: all 0.3s ease-in-out;
  font-size: 14px;
  color: #ffffff;
  border-radius: 3px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 0 10px;
  text-align: center;
  .iconfont {
    font-size: 12px;
  }
}
.pano-select-mode-dark {
  background: #333333;
  box-shadow: -4px 3px 5px 1px rgb(0 0 0 / 60%);
}
.pano-select-small {
  font-size: $font-size-small;
  line-height: 30px;
}
.pano-select-medium {
  font-size: $font-size-medium;
  line-height: 36px;
}
.pano-select-large {
  font-size: $font-size-large;
  line-height: 42px;
}
.pano-select-container:hover {
  cursor: pointer;
  background: $color-theme-deep;
}
.pano-select-mode-dark:hover {
  cursor: pointer;
  background: #383838;
}
</style>