<template>
    <div class="pano-circle-close-container" @click="click()">
        <svg width="32" height="32">
            <g transform="translate(1 1)" fill="none" fill-rule="evenodd">
                <circle class="pano-circle" stroke="#777777" fill-opacity=".9" fill="#fff" cx="15" cy="15" r="15"></circle>
                    <path class="pano-path" d="M16.327 15.005l4.397-4.401a.94.94 0 00-1.328-1.33L15 13.677l-4.398-4.401a.939.939 0 10-1.328 1.329l4.399 4.401-4.399 4.402a.94.94 0 00.665 1.604c.24 0 .48-.092.664-.276L15 16.333l4.397 4.401a.935.935 0 001.328 0 .939.939 0 000-1.328l-4.398-4.401z" fill="#333333"></path></g></svg>
    </div>
</template>
<script>
export default {
    methods:{
        click(){
            this.$emit('click');
        }
    }
}
</script>
<style lang="scss">
    .pano-circle-close-container{
        display: inline-block;
        cursor: pointer;
    }
    .pano-circle-close-container:hover{
        .pano-circle{
            fill: $color-theme;
            stroke: $color-theme;
        }
        .pano-path{
            fill: #ffffff;
        }
    }
</style>