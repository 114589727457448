import axios from 'axios'
import { getToken } from '@/common/js/auth'

axios.defaults.timeout = 15000
axios.defaults.baseURL = process.env.VUE_APP_BASE_API

axios.interceptors.request.use(
  config => {

    if (config.data instanceof FormData) {
      config.headers = {
        'Content-Type': 'multipart/form-data'
      }
    } else {
      config.headers = {
        'Content-Type': 'application/json;charset=UTF-8'
      }
      config.data = JSON.stringify(config.data)
    }
    const token = getToken()
    if (token) {
      config.headers['Authorization'] = `Bearer ${getToken()}`
    }
    return config
  },
  (err) => {
    return Promise.reject(err)
  })

axios.interceptors.response.use(
  // 请求成功
  res => {
    const data = res.data
    if (data.code === '0') {
      return Promise.resolve(data.data)
    } else if (data.code === 401) {
      window.location.href = window.location.href.replace(/#.*/, '#/login')
    } else {
      // alert(data.msg)
      return Promise.reject(data)
    }
  },
  // 请求失败
  error => {

    const { response } = error

    if (response.status === 401) {
      // debugger
      // window.location.href = `${window.location.host}/login`
      window.APP.$router.replace("/login")
      return Promise.reject(error)
    }
    let msg = '服务器异常'
    // Toast('服务器异常')
    if (!response) {
      // 处理断网的情况
      // eg:请求超时或断网时，更新state的network状态
      // network状态在app.vue中控制着一个全局的断网提示组件的显示隐藏
      // 关于断网组件中的刷新重新获取数据，会在断网组件中说明
      msg = '后端服务未启用或未打开网络'
    }
    alert(msg)
    return Promise.reject(error)
  })
/**
 * 封装get方法
 * @param url
 * @param data
 * @returns {Promise}
 */

export function fetch(url, params = {}) {
  return new Promise((resolve, reject) => {
    axios.get(url, {
      params: params
    })
      .then(data => {
        resolve(data)
      })
      .catch(err => {
        reject(err)
      })
  })
}
/**
 * 封装post请求
 * @param url
 * @param data
 * @returns {Promise}
 */

export function post(url, data = {}, params = {}) {
  return new Promise((resolve, reject) => {
    axios.post(url, data, params)
      .then(data => {
        resolve(data)
      }, err => {
        reject(err)
      })
  })
}
