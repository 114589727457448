<template>
      <div class="hotspot-font-size-select-container">
          <div class="hotspot-font-size-select-title">标题字号</div>
          <div class="hotspot-font-size-select-item-container">
            <div class="hotspot-font-size-select-item" :class="{'hotspot-font-size-select-item-active':item==value}" v-for="item in fontSizes" :key="item" @click="onFontSizeHandle(item)">
                <div class="hotspot-font-size-select-item-circle"></div>{{item}}
            </div>
          </div>
        </div>
</template>
<script>
export default {
    props:{
        value:Number
    },
    data(){
        return {
            fontSizes:[
               12,14,16
            ]
        }
    },
    methods:{
        onFontSizeHandle(fontSize){
           this.$emit('input',fontSize)
        }
    }
    
}
</script>
<style lang="scss">
    
      .hotspot-font-size-select-container{
        color: #ffffff;
        margin-top: 15px;
        .hotspot-font-size-select-title{
          margin-bottom: 10px;
        }
        .hotspot-font-size-select-item-container{
          display: flex;
          align-items: center;
          justify-content: space-between;
          .hotspot-font-size-select-item{
            display: flex;
            justify-content: center;
            align-items: center;
            height: 24px;
            cursor: pointer;
            .hotspot-font-size-select-item-circle{
                height: 16px;
                width: 16px;
                border-radius: 50%;
                border: 1px solid #666666;
                box-sizing: border-box;
                margin-right: 5px;
            }
          }
          .hotspot-font-size-select-item-active{
            .hotspot-font-size-select-item-circle{
               background: #eeeeee;
            }
          }
          
        }
      }
</style>